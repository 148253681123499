import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import Tilt from "react-parallax-tilt";

import { Button, Container } from "react-bootstrap";

import Anchor from "../elements/Anchor";
import AnchorPrismic from "../elements/AnchorPrismic";
import Icon from "../elements/Icon";
import { CheckMark } from "../common/Icons";

import "../../styles/components/elements/tilt-layers.scss";

const Hero = ({ slice, settings }) => {
  if (!slice) return null;

  const { logo_white: logo } = settings.data;
  const {
    background,
    check_1: check1,
    check_2: check2,
    check_3: check3,
    cta,
    cta_label: ctaLabel,
    layer_bottom: layerBottom,
    layer_top: layerTop,
    subtitle,
    title,
  } = slice.primary;

  return (
    <section className="slice hero image-effect mb-6">
      <div className="image-effect__content section-background clip clip--bottom">
        <figure
          className="section-background__figure bg-primary"
          aria-hidden="true"
        >
          <GatsbyImage
            image={background.gatsbyImageData}
            alt={background.alt}
          />
        </figure>
        <Container className="pt-7 pt-md-9 pb-5 text-center text-white">
          <Icon icon={logo} />
          <h1 className="mt-5 mt-md-6">{title}</h1>
          <div className="h4">
            <RichText
              render={subtitle.richText}
              serializeHyperlink={AnchorPrismic}
            />
          </div>
          <div className="d-flex justify-content-center flex-wrap my-4 mt-md-5 mb-md-6">
            <p className="d-flex align-items-center mx-3 px-2">
              <CheckMark />
              <span className="ms-2">{check1}</span>
            </p>
            <p className="d-flex align-items-center mx-3 px-2">
              <CheckMark />
              <span className="ms-2">{check2}</span>
            </p>
            <p className="d-flex align-items-center mx-3 px-2">
              <CheckMark />
              <span className="ms-2">{check3}</span>
            </p>
          </div>
          <Button
            as={Anchor}
            href={cta.url}
            target={cta.target}
            variant="secondary"
            size="lg"
          >
            <RichText render={ctaLabel.richText} />
          </Button>
        </Container>
      </div>
      <div className="image-effect__media">
        <div className="image-effect__media-container">
          <Tilt className="tilt-layers" perspective={2000}>
            <GatsbyImage
              image={layerBottom.gatsbyImageData}
              alt={layerBottom.alt}
              className="layer layer--bottom"
            />
            <GatsbyImage
              image={layerTop.gatsbyImageData}
              alt={layerTop.alt}
              className="layer layer--top"
            />
          </Tilt>
        </div>
      </div>
    </section>
  );
};

export default Hero;
