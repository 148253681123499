import React from "react";

import Hero from "../slices/Hero";
import KeyPoints from "../slices/KeyPoints";
import HowTo from "../slices/HowTo";
import HorizontalAccordion from "../slices/HorizontalAccordion";
import Faq from "../slices/Faq";
import ContactForm from "../slices/ContactForm";

const SliceZone = ({ slices, settings }) => {
  const sliceComponents = {
    hero: Hero,
    key_points: KeyPoints,
    how_to: HowTo,
    horizontal_accordion: HorizontalAccordion,
    faq: Faq,
    contact_form: ContactForm,
  };

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];
    const key = slice.id + index;

    if (SliceComponent) {
      return <SliceComponent slice={slice} settings={settings} key={key} />;
    }

    return null;
  });
};

export default SliceZone;
