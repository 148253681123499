/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { RichText } from "prismic-reactjs";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { useForm } from "react-hook-form";
import Recaptcha from "react-google-recaptcha";

import AnchorPrismic from "../elements/AnchorPrismic";

import "../../styles/components/slices/contact-form.scss";

const ContactForm = ({ slice, settings }) => {
  if (!slice) return null;

  const [notification, setNotification] = useState();
  const [recaptcha, setRecaptcha] = useState(false);

  const { lang } = settings;

  const {
    title,
    content,
    name,
    lastname,
    company,
    email,
    phone,
    message,
    acceptance,
    send,
    required_field_error: requiredFieldError,
    email_field_error: emailFieldError,
    phone_field_error: phoneFieldError,
    submit_success_message: submitSuccessMessage,
    submit_error_message: submitErrorMessage,
  } = slice.primary;

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm();

  const onSubmit = async (d) => {
    if (!recaptcha) return null;

    await fetch(`/api/contact-form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name: d.name,
        last_name: d.lastname,
        company: d.company,
        email: d.email,
        phone: d.phone,
        message: d.message,
      }),
    })
      .then((res) => res.json())
      .then((res) => setNotification(res))
      .catch(() =>
        setNotification({ success: false, message: submitErrorMessage })
      );

    return null;
  };

  return (
    <section className="slice contact-form section-background overflow-visible">
      <figure className="section-background__figure" aria-hidden="true">
        <StaticImage
          src="../../images/map-bg.jpg"
          quality={100}
          placeholder="blurred"
          objectFit="contain"
          alt=""
        />
      </figure>
      <Container className="section-background__content">
        <header className="text-center mb-6">
          <h2 className="text-primary mb-4">{title}</h2>
          <div className="mw-610 mx-auto fs-4">
            <RichText
              render={content.richText}
              serializeHyperlink={AnchorPrismic}
            />
          </div>
        </header>
        <Form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="mx-lg-7 py-5 px-3 px-lg-6 rounded bg-white"
          style={{ "--bs-bg-opacity": "0.9" }}
        >
          <Row>
            <Form.Group
              as={Col}
              lg={6}
              controlId="contact.name"
              className="mb-4"
            >
              <Form.Label>
                {name} <span className="small">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.name}
                {...register("name", { required: true })}
              />
              <Form.Control.Feedback type="invalid">
                {requiredFieldError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              lg={6}
              controlId="contact.lastname"
              className="mb-4"
            >
              <Form.Label>
                {lastname} <span className="small">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.lastname}
                {...register("lastname", { required: true })}
              />
              <Form.Control.Feedback type="invalid">
                {requiredFieldError}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group controlId="contact.company" className="mb-4">
            <Form.Label>
              {company} <span className="small">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!errors.company}
              {...register("company", { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {requiredFieldError}
            </Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Form.Group
              as={Col}
              lg={6}
              controlId="contact.email"
              className="mb-4"
            >
              <Form.Label>
                {email} <span className="small">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                isInvalid={!!errors.email}
                {...register("email", {
                  required: true,
                  pattern: /^.+@.+?\.[a-zA-Z]{2,}$/i,
                })}
              />
              <Form.Control.Feedback type="invalid">
                {emailFieldError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              lg={6}
              controlId="contact.phone"
              className="mb-4"
            >
              <Form.Label>
                {phone} <span className="small">*</span>
              </Form.Label>
              <Form.Control
                type="tel"
                isInvalid={!!errors.phone}
                {...register("phone", { required: true })}
              />
              <Form.Control.Feedback type="invalid">
                {phoneFieldError}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group controlId="contact.message" className="mb-4">
            <Form.Label>{message}</Form.Label>
            <Form.Control as="textarea" rows={5} {...register("message")} />
          </Form.Group>
          <Form.Group controlId="contact.acceptance" className="mb-4">
            <Form.Check inline type="checkbox">
              <Form.Check.Input
                type="checkbox"
                isInvalid={!!errors.acceptance}
                {...register("acceptance", { required: true })}
              />
              <Form.Check.Label>
                <RichText
                  render={acceptance.richText}
                  serializeHyperlink={AnchorPrismic}
                />
              </Form.Check.Label>
              <Form.Control.Feedback type="invalid">
                {requiredFieldError}
              </Form.Control.Feedback>
            </Form.Check>
          </Form.Group>
          <Recaptcha
            sitekey="6Ld1UC4gAAAAABNfxJOY7zTJ4OoOYdNtqaGDdEBG"
            onChange={() => setRecaptcha(true)}
            onExpired={() => setRecaptcha(false)}
            onErrored={() => setRecaptcha(false)}
            hl={lang}
          />
          <div className="text-center mt-5">
            <Button
              variant="primary"
              className="px-5 py-2 fs-5"
              type="submit"
              disabled={!recaptcha || isSubmitting}
            >
              {send}
              {isSubmitting && (
                <Spinner animation="border" size="sm" className="ms-2" />
              )}
            </Button>
          </div>
          {notification && (
            <Alert
              variant={notification.success ? "success" : "danger"}
              className="mt-4 text-center"
              onClose={() => setNotification(null)}
              dismissible
            >
              {notification.success ? submitSuccessMessage : submitErrorMessage}
            </Alert>
          )}
        </Form>
      </Container>
    </section>
  );
};

export default ContactForm;
