import React from "react";
import { Card, Container } from "react-bootstrap";
import { RichText } from "prismic-reactjs";
import { Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import AnchorPrismic from "../elements/AnchorPrismic";
import Icon from "../elements/Icon";

import "swiper/css";
import "../../styles/components/elements/cards.scss";
import "../../styles/components/elements/swiper-pagination.scss";

const KeyPoints = ({ slice }) => {
  if (!slice) return null;

  const { title, content } = slice.primary;

  const cards = slice.items.map((card) => {
    return (
      <SwiperSlide key={card.title} className="h-auto">
        <Card className="card-hover h-100">
          <Card.Body>
            <Icon icon={card.icon} />
            <h3 className="h5 fw-bold text-primary pt-4">{card.title}</h3>
            <RichText render={card.content.richText} />
          </Card.Body>
        </Card>
      </SwiperSlide>
    );
  });

  return (
    <section className="slice key-points mb-6">
      <Container className="mw-920 mb-6 text-center">
        <h2 className="mb-5 text-primary">{title}</h2>
        <RichText
          render={content.richText}
          serializeHyperlink={AnchorPrismic}
        />
      </Container>
      <Container className="p-0">
        <Swiper
          className="p-3"
          modules={[Pagination, A11y]}
          slidesPerView={1}
          spaceBetween={30}
          pagination={{ clickable: true }}
          breakpoints={{
            567: {
              slidesPerView: 2.3,
            },
            1025: {
              slidesPerView: 3,
            },
          }}
        >
          {cards}
        </Swiper>
      </Container>
    </section>
  );
};

export default KeyPoints;
