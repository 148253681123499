import React from "react";

// ----------------------------------------------------------------------------
// Apps & Socials
// ----------------------------------------------------------------------------
const GooglePlay = ({ title = "Get it on Google Play" }) => (
  <svg viewBox="0 0 222 67" fill="none" xmlns="http://www.w3.org/2000/svg" role="presentation" className="icon icon--google-play">
    <title>{title}</title>
    <g clipPath="url(#clip0_417_1582)">
      <path d="M33.7067 66.4253H187.683C196.571 66.4253 205.095 62.9485 211.38 56.7598C217.665 50.5711 221.196 42.1774 221.196 33.4253C221.196 24.6731 217.665 16.2795 211.38 10.0908C205.095 3.90205 196.571 0.425293 187.683 0.425293H33.7067C24.8184 0.425293 16.2942 3.90205 10.0092 10.0908C3.72422 16.2795 0.193359 24.6731 0.193359 33.4253C0.193359 42.1774 3.72422 50.5711 10.0092 56.7598C16.2942 62.9485 24.8184 66.4253 33.7067 66.4253V66.4253Z" fill="#F4F4F3" />
      <path d="M57.4302 33.9045C57.433 32.724 57.7742 31.5681 58.4145 30.57C59.0547 29.5719 59.9681 28.772 61.0496 28.2623C59.44 25.9941 56.7765 24.877 53.7647 24.877C52.2242 24.877 50.7787 25.0697 49.5176 25.7842C49.1622 25.9849 48.7596 26.0906 48.35 26.0906C47.9403 26.0906 47.5377 25.9849 47.1824 25.7842C45.9183 25.0697 44.4728 24.877 42.9352 24.877C38.0172 24.877 34.0321 27.8313 34.0321 34.0038C34.0321 40.1762 38.0172 50.1649 42.9352 50.1649C44.0467 50.1649 45.1092 49.6971 46.0882 49.2293C46.7928 48.8931 47.5656 48.7184 48.3485 48.7184C49.1315 48.7184 49.9043 48.8931 50.6089 49.2293C51.5907 49.6971 52.6532 50.1649 53.7762 50.1649C57.3006 50.1649 60.3499 45.0331 61.7925 39.8501C60.5255 39.4323 59.4233 38.6341 58.6409 37.5677C57.8584 36.5012 57.435 35.2202 57.4302 33.9045V33.9045Z" fill="#31363C" />
      <path d="M52.5611 21.551C53.7918 20.2174 54.4904 18.4901 54.5277 16.6885C52.7254 16.9738 51.0797 17.8666 49.8718 19.2143C48.6638 20.562 47.9687 22.2809 47.9051 24.0773C49.7168 23.8188 51.3714 22.9211 52.5611 21.551V21.551Z" fill="#31363C" />
      <path d="M82.91 25.4438C83.5162 25.1513 84.0181 24.6847 84.3497 24.1056C84.6966 23.4723 84.8693 22.7608 84.8507 22.0415C84.8686 21.3196 84.696 20.6054 84.3497 19.9689C84.018 19.3864 83.5165 18.9153 82.91 18.6164C82.2135 18.2872 81.4479 18.125 80.6755 18.143H78.2223V25.906H80.6755C81.4467 25.925 82.2117 25.7667 82.91 25.4438V25.4438ZM79.2502 18.968H80.6899C81.6996 18.968 82.4742 19.2383 83.0136 19.7789C83.553 20.3195 83.8227 21.0756 83.8227 22.0471C83.8227 23.0055 83.555 23.7493 83.0194 24.2785C82.4838 24.8116 81.7064 25.0752 80.6899 25.0752H79.2502V18.968Z" fill="#31363C" />
      <path d="M90.2467 20.0852C89.7541 19.8327 89.2069 19.7009 88.6515 19.7009C88.0962 19.7009 87.549 19.8327 87.0564 20.0852C86.5835 20.3417 86.1934 20.7238 85.9305 21.1881C85.6495 21.6955 85.5076 22.2662 85.5187 22.8439C85.5061 23.4232 85.6438 23.9961 85.919 24.5083C86.1736 24.9743 86.5583 25.3589 87.0276 25.6169C87.5108 25.878 88.0543 26.0118 88.6055 26.0053C89.1658 26.0118 89.7186 25.8781 90.2122 25.6169C90.693 25.3595 91.0917 24.9757 91.3639 24.5083C91.6534 24.0006 91.7995 23.426 91.7872 22.8439C91.7998 22.2642 91.6578 21.6913 91.3755 21.1825C91.111 20.7199 90.7199 20.3397 90.2467 20.0852V20.0852ZM90.4339 24.0915C90.2502 24.4144 89.9777 24.6797 89.6478 24.857C89.328 25.028 88.9694 25.1168 88.6055 25.115C88.2503 25.118 87.9003 25.0311 87.589 24.8627C87.2694 24.6875 87.0106 24.4218 86.8461 24.1C86.6466 23.7111 86.5477 23.2796 86.5582 22.8439C86.5468 22.4109 86.6458 21.982 86.8461 21.5964C87.0157 21.2744 87.2781 21.0091 87.6005 20.8337C87.9181 20.6681 88.272 20.5816 88.6314 20.5816C88.9907 20.5816 89.3446 20.6681 89.6622 20.8337C89.989 21.009 90.2571 21.2739 90.4339 21.5964C90.629 21.9842 90.7306 22.4112 90.7306 22.8439C90.7306 23.2767 90.629 23.7037 90.4339 24.0915Z" fill="#31363C" />
      <path d="M96.7513 21.0606L98.2428 25.9061H99.3082L101.24 19.8018H100.224L98.8216 24.9365L97.3157 19.8018H96.2647L94.7271 24.9251L93.3018 19.8018H92.2479L94.1944 25.9061H95.2569L96.7513 21.0606Z" fill="#31363C" />
      <path d="M105.122 19.6911C104.723 19.6877 104.329 19.7751 103.97 19.9462C103.631 20.1088 103.344 20.3599 103.141 20.6721V19.8016H102.11V25.906H103.141V22.5292C103.102 22.0003 103.275 21.4774 103.622 21.0718C103.789 20.9027 103.99 20.7706 104.213 20.684C104.436 20.5975 104.675 20.5586 104.914 20.57C105.148 20.5579 105.382 20.5949 105.601 20.6785C105.819 20.7621 106.017 20.8903 106.181 21.0548C106.511 21.4483 106.671 21.953 106.628 22.4611V25.9145H107.644V22.308C107.644 21.4575 107.411 20.8138 106.942 20.363C106.699 20.1376 106.413 19.962 106.1 19.8466C105.788 19.7313 105.455 19.6784 105.122 19.6911V19.6911Z" fill="#31363C" />
      <path d="M109.585 17.6638H108.557V25.906H109.585V17.6638Z" fill="#31363C" />
      <path d="M112.147 25.6169C112.632 25.8783 113.176 26.0121 113.728 26.0053C114.288 26.0118 114.84 25.8781 115.332 25.6169C115.813 25.3602 116.212 24.9763 116.484 24.5083C116.773 24.0006 116.92 23.426 116.907 22.8439C116.92 22.2642 116.778 21.6913 116.495 21.1825C116.232 20.7203 115.842 20.3401 115.37 20.0852C114.877 19.8327 114.33 19.7009 113.774 19.7009C113.219 19.7009 112.672 19.8327 112.179 20.0852C111.706 20.3417 111.316 20.7238 111.053 21.1881C110.772 21.6955 110.63 22.2662 110.642 22.8439C110.63 23.4235 110.769 23.9964 111.045 24.5083C111.298 24.9737 111.68 25.3584 112.147 25.6169V25.6169ZM111.975 21.5964C112.142 21.274 112.404 21.0084 112.726 20.8337C113.044 20.6681 113.399 20.5816 113.759 20.5816C114.118 20.5816 114.473 20.6681 114.791 20.8337C115.116 21.0146 115.381 21.2845 115.554 21.6106C115.749 21.9984 115.851 22.4253 115.851 22.8581C115.851 23.2909 115.749 23.7179 115.554 24.1057C115.37 24.4286 115.098 24.6939 114.768 24.8712C114.449 25.0422 114.091 25.131 113.728 25.1292C113.372 25.1326 113.021 25.0457 112.709 24.8769C112.39 24.7015 112.132 24.4358 111.969 24.1142C111.769 23.7252 111.67 23.2938 111.681 22.8581C111.67 22.4245 111.771 21.9951 111.975 21.6106V21.5964Z" fill="#31363C" />
      <path d="M122.813 20.916C122.6 20.5522 122.29 20.2523 121.917 20.0484C121.486 19.8099 120.998 19.6904 120.503 19.7025C119.977 19.6958 119.46 19.8311 119.006 20.0938C118.556 20.3525 118.189 20.7321 117.949 21.1882C117.684 21.6963 117.552 22.2615 117.564 22.8327C117.554 23.4045 117.686 23.9701 117.949 24.48C118.186 24.9471 118.55 25.3399 119 25.6141C119.45 25.8821 119.967 26.0207 120.492 26.0139C120.985 26.025 121.472 25.9035 121.9 25.6623C122.279 25.4534 122.595 25.1478 122.813 24.7777V25.9118H123.852V19.8017H122.813V20.916ZM122.525 24.0518C122.35 24.3809 122.084 24.655 121.759 24.8429C121.439 25.0288 121.074 25.1268 120.702 25.1264C120.332 25.1268 119.969 25.0289 119.651 24.8429C119.328 24.6542 119.065 24.3801 118.891 24.0518C118.694 23.6753 118.595 23.2563 118.603 22.8327C118.595 22.4136 118.694 21.9994 118.891 21.6277C119.065 21.3029 119.329 21.0328 119.651 20.8479C119.972 20.6703 120.334 20.5784 120.702 20.5814C121.074 20.5798 121.439 20.6778 121.759 20.865C122.084 21.0498 122.349 21.321 122.525 21.6475C122.722 22.0191 122.821 22.4334 122.813 22.8525C122.82 23.269 122.723 23.681 122.531 24.0518H122.525Z" fill="#31363C" />
      <path d="M131.183 25.906V17.6638H130.144V20.8932C129.911 20.5298 129.583 20.2357 129.194 20.0426C128.779 19.824 128.316 19.7109 127.846 19.7137C127.316 19.7068 126.795 19.8421 126.337 20.105C125.887 20.3646 125.521 20.744 125.28 21.1994C125.015 21.7076 124.883 22.2727 124.895 22.8439C124.885 23.4158 125.017 23.9814 125.28 24.4912C125.518 24.9564 125.884 25.3462 126.336 25.616C126.788 25.8858 127.309 26.0246 127.837 26.0166C128.329 26.0282 128.814 25.9066 129.24 25.665C129.618 25.4533 129.931 25.1434 130.144 24.7691V25.9032L131.183 25.906ZM129.862 24.0517C129.684 24.3819 129.416 24.6561 129.087 24.8428C128.766 25.0284 128.4 25.1263 128.027 25.1263C127.657 25.1267 127.294 25.0288 126.976 24.8428C126.654 24.6536 126.392 24.3797 126.219 24.0517C126.021 23.6756 125.921 23.2563 125.931 22.8326C125.922 22.4134 126.021 21.9988 126.219 21.6276C126.392 21.3024 126.654 21.032 126.976 20.8478C127.297 20.6702 127.659 20.5783 128.027 20.5813C128.4 20.5801 128.766 20.678 129.087 20.8649C129.41 21.0509 129.674 21.3218 129.85 21.6474C130.047 22.019 130.146 22.4333 130.138 22.8524C130.147 23.2685 130.052 23.6804 129.862 24.0517V24.0517Z" fill="#31363C" />
      <path d="M136.285 25.6169C136.769 25.8783 137.312 26.0121 137.863 26.0053C138.424 26.0114 138.976 25.8778 139.47 25.6169C139.951 25.3598 140.35 24.9759 140.622 24.5083C140.914 24.0014 141.061 23.4264 141.048 22.8439C141.061 22.2642 140.919 21.6913 140.636 21.1825C140.371 20.7212 139.982 20.3413 139.51 20.0852C139.018 19.8327 138.471 19.7009 137.915 19.7009C137.36 19.7009 136.813 19.8327 136.32 20.0852C135.847 20.3417 135.457 20.7238 135.194 21.1881C134.913 21.6955 134.771 22.2662 134.782 22.8439C134.77 23.4232 134.907 23.9961 135.183 24.5083C135.436 24.9735 135.818 25.3581 136.285 25.6169V25.6169ZM136.11 21.6106C136.279 21.2886 136.542 21.0232 136.864 20.8479C137.182 20.6823 137.536 20.5957 137.895 20.5957C138.254 20.5957 138.608 20.6823 138.926 20.8479C139.252 21.0239 139.52 21.2887 139.698 21.6106C139.901 21.9953 140 22.4249 139.986 22.8581C140.002 23.2916 139.903 23.7217 139.698 24.1057C139.514 24.4293 139.24 24.6948 138.909 24.8712C138.59 25.0425 138.232 25.1313 137.869 25.1292C137.513 25.1318 137.162 25.045 136.85 24.8769C136.531 24.7015 136.273 24.4358 136.11 24.1142C135.91 23.7252 135.811 23.2938 135.822 22.8581C135.81 22.4251 135.909 21.9962 136.11 21.6106V21.6106Z" fill="#31363C" />
      <path d="M144.734 20.5701C144.968 20.5582 145.202 20.5953 145.42 20.6789C145.638 20.7624 145.836 20.8906 146.001 21.055C146.333 21.4444 146.499 21.9453 146.464 22.4528V25.9062H147.481V22.3082C147.481 21.4576 147.247 20.814 146.781 20.3631C146.536 20.1365 146.248 19.9603 145.933 19.8449C145.618 19.7295 145.283 19.6772 144.947 19.6912C144.548 19.6878 144.154 19.7752 143.795 19.9464C143.455 20.1081 143.167 20.3593 142.963 20.6722V19.8018H141.929V25.9062H142.957V22.5293C142.92 22.0006 143.092 21.4783 143.438 21.072C143.606 20.9026 143.808 20.7703 144.031 20.6838C144.254 20.5973 144.494 20.5585 144.734 20.5701V20.5701Z" fill="#31363C" />
      <path d="M154.273 25.906V25.0554H153.516C153.277 25.08 153.039 25.014 152.848 24.8711C152.775 24.7844 152.721 24.6844 152.688 24.5768C152.655 24.4693 152.643 24.3563 152.655 24.2445V20.638H154.273V19.7874H152.655V18.2649H151.627V19.8016H150.824V20.6522H151.627V24.2502C151.627 24.8418 151.77 25.2681 152.056 25.5289C152.42 25.815 152.881 25.9548 153.346 25.9202L154.273 25.906Z" fill="#31363C" />
      <path d="M156.234 22.5292C156.199 21.9983 156.377 21.4752 156.729 21.0718C156.896 20.9027 157.098 20.7705 157.321 20.684C157.544 20.5975 157.783 20.5586 158.022 20.57C158.256 20.5579 158.49 20.5949 158.708 20.6785C158.927 20.7621 159.125 20.8903 159.289 21.0548C159.623 21.4435 159.789 21.945 159.753 22.4526V25.906H160.76V22.308C160.776 21.8099 160.665 21.3158 160.438 20.8705C160.24 20.4944 159.93 20.1861 159.551 19.9859C159.159 19.7892 158.724 19.6918 158.284 19.7024C157.867 19.6963 157.455 19.7835 157.078 19.9576C156.733 20.1166 156.442 20.3682 156.237 20.6834V17.6638H155.203V25.906H156.234V22.5292Z" fill="#31363C" />
      <path d="M165.923 24.843C165.593 25.0568 165.204 25.1638 164.809 25.1492C164.548 25.1579 164.287 25.1148 164.043 25.0225C163.799 24.9301 163.576 24.7904 163.388 24.6116C163.2 24.4327 163.051 24.2185 162.949 23.9816C162.846 23.7447 162.793 23.4899 162.793 23.2325H167.746C167.768 23.0291 167.78 22.8247 167.78 22.6201C167.79 22.0986 167.664 21.5834 167.415 21.1231C167.172 20.6858 166.809 20.325 166.367 20.0825C165.89 19.8222 165.351 19.6911 164.806 19.7026C164.248 19.6918 163.696 19.8237 163.205 20.0854C162.745 20.3402 162.369 20.7214 162.125 21.1826C161.86 21.6969 161.729 22.2676 161.742 22.8441C161.73 23.4226 161.866 23.9949 162.137 24.5084C162.387 24.9722 162.765 25.3567 163.228 25.617C163.711 25.8795 164.255 26.0134 164.806 26.0054C165.469 26.0298 166.121 25.8363 166.66 25.4554C167.144 25.1063 167.495 24.607 167.657 24.0378H166.548C166.432 24.3645 166.213 24.6465 165.923 24.843V24.843ZM163.435 21.0437C163.797 20.7185 164.273 20.5428 164.763 20.5532C165.106 20.5491 165.445 20.6239 165.753 20.7715C166.046 20.912 166.292 21.1325 166.462 21.4066C166.641 21.708 166.731 22.0527 166.721 22.4018H162.808C162.83 21.8859 163.055 21.3988 163.435 21.0437V21.0437Z" fill="#31363C" />
      <path d="M138.969 35.8722C138.171 35.5429 137.35 35.2701 136.513 35.0556C135.672 34.8232 135.02 34.6162 134.555 34.4347C134.105 34.2633 133.703 33.9904 133.38 33.638C133.054 33.2637 132.884 32.7813 132.905 32.2884C132.888 31.9484 132.95 31.6091 133.089 31.2971C133.227 30.9852 133.437 30.7091 133.703 30.4908C134.29 30.0436 135.02 29.8156 135.761 29.8472C136.542 29.8092 137.31 30.0453 137.93 30.5135C138.432 30.8852 138.766 31.4359 138.86 32.0474H141.071C141.028 31.4933 140.867 30.9542 140.598 30.4656C140.329 29.977 139.959 29.5499 139.51 29.2121C138.589 28.5033 137.406 28.1479 135.96 28.146C135.043 28.1279 134.134 28.3059 133.294 28.6677C132.56 28.9819 131.934 29.4965 131.488 30.1506C131.057 30.8046 130.833 31.5708 130.846 32.3508C130.801 33.1575 131.031 33.9561 131.5 34.619C131.906 35.1572 132.448 35.5814 133.072 35.8495C133.866 36.1719 134.683 36.4345 135.517 36.6349C136.184 36.8038 136.841 37.0073 137.486 37.2445C137.952 37.4159 138.369 37.6939 138.704 38.0554C139.04 38.4521 139.213 38.9574 139.191 39.473C139.2 39.8182 139.13 40.1609 138.986 40.4757C138.842 40.7904 138.628 41.069 138.359 41.2905C137.806 41.7649 137.05 42.0012 136.093 41.9993C135.091 41.9993 134.325 41.7583 133.789 41.2706C133.276 40.8005 132.957 40.1604 132.894 39.473H130.748C130.749 40.2573 130.996 41.0221 131.457 41.6619C131.94 42.3151 132.594 42.8274 133.349 43.1448C134.211 43.5112 135.142 43.6929 136.081 43.6778C137.04 43.7005 137.99 43.4912 138.848 43.0682C139.575 42.7087 140.187 42.1582 140.616 41.4776C141.007 40.8476 141.214 40.1236 141.215 39.3851C141.258 38.577 141.024 37.7781 140.55 37.1169C140.136 36.579 139.593 36.1511 138.969 35.8722Z" fill="#31363C" />
      <path d="M83.7968 28.4294L78.2223 43.5302H80.3444L81.5768 40.1534H88.3031L89.5412 43.5302H91.6662L86.1119 28.4294H83.7968ZM82.1527 38.5401L84.9428 30.8706L87.7301 38.5401H82.1527Z" fill="#31363C" />
      <path d="M103.953 32.1579C103.063 31.6453 102.049 31.3816 101.019 31.3952C100.055 31.3734 99.1031 31.6106 98.2658 32.0813C97.5307 32.4849 96.916 33.0715 96.4835 33.7825V31.5908H94.4679V49.1951H96.4835V41.33C96.929 42.0387 97.5503 42.6241 98.2888 43.0312C99.1183 43.5004 100.062 43.7377 101.019 43.7174C102.053 43.7324 103.071 43.4608 103.956 42.9333C104.84 42.4059 105.557 41.644 106.023 40.7346C106.537 39.7355 106.795 38.6278 106.774 37.5081C106.799 36.3924 106.541 35.2882 106.023 34.2957C105.549 33.407 104.832 32.6666 103.953 32.1579V32.1579ZM104.163 39.9011C103.825 40.547 103.306 41.0842 102.668 41.4491C102.044 41.7996 101.337 41.9846 100.618 41.986C99.8989 41.9875 99.1916 41.8055 98.5653 41.4576C97.9259 41.0908 97.4041 40.5544 97.0594 39.9096C96.6882 39.1794 96.4949 38.3742 96.4949 37.5577C96.4949 36.7412 96.6882 35.9359 97.0594 35.2058C97.4048 34.5615 97.9264 34.0253 98.5653 33.6577C99.1869 33.3027 99.8939 33.1186 100.613 33.1247C101.336 33.1169 102.049 33.2969 102.68 33.6464C103.316 34.0033 103.835 34.5328 104.174 35.1718C104.557 35.8979 104.747 36.7073 104.727 37.5251C104.743 38.351 104.549 39.1677 104.163 39.9011V39.9011Z" fill="#31363C" />
      <path d="M119.274 32.1579C118.385 31.6453 117.37 31.3816 116.34 31.3952C115.376 31.3734 114.424 31.6106 113.587 32.0813C112.851 32.4834 112.236 33.0703 111.805 33.7825V31.5908H109.789V49.1951H111.805V41.33C112.248 42.0392 112.869 42.6248 113.607 43.0312C114.438 43.5001 115.383 43.7373 116.34 43.7174C117.374 43.7329 118.391 43.4614 119.276 42.9339C120.161 42.4064 120.876 41.6442 121.341 40.7346C121.858 39.7362 122.117 38.6282 122.096 37.5081C122.121 36.392 121.862 35.2875 121.341 34.2957C120.87 33.4062 120.153 32.6653 119.274 32.1579ZM119.484 39.9011C119.146 40.547 118.628 41.0842 117.99 41.4491C117.361 41.807 116.646 41.9921 115.919 41.985C115.202 41.9902 114.495 41.8051 113.875 41.4491C113.236 41.0823 112.714 40.5459 112.369 39.9011C111.998 39.1709 111.805 38.3657 111.805 37.5492C111.805 36.7327 111.998 35.9274 112.369 35.1973C112.709 34.5626 113.221 34.0325 113.847 33.6654C114.474 33.2982 115.191 33.1082 115.919 33.1162C116.644 33.1085 117.358 33.2884 117.99 33.6379C118.626 33.9948 119.145 34.5243 119.484 35.1633C119.867 35.8893 120.057 36.6988 120.037 37.5166C120.058 38.3444 119.868 39.1641 119.484 39.9011V39.9011Z" fill="#31363C" />
      <path d="M147.008 28.5825H144.993V31.5908H143.427V33.2352H144.996V40.2724C144.996 41.4349 145.276 42.2704 145.837 42.7788C146.412 43.2892 147.239 43.5415 148.359 43.5415H150.173V41.8545H148.693C148.071 41.8545 147.636 41.7326 147.386 41.4944C147.135 41.2563 147.008 40.8423 147.008 40.2611V33.2352H150.176V31.5908H147.008V28.5825Z" fill="#31363C" />
      <path d="M161.65 32.1465C160.687 31.6529 159.616 31.3953 158.53 31.3953C157.444 31.3953 156.374 31.6529 155.411 32.1465C154.483 32.6449 153.719 33.3934 153.208 34.3041C152.656 35.2943 152.378 36.4099 152.402 37.5392C152.376 38.6731 152.646 39.7945 153.185 40.7969C153.683 41.7075 154.435 42.4591 155.353 42.9631C156.297 43.4779 157.361 43.7408 158.44 43.7258C159.537 43.7398 160.619 43.4772 161.584 42.9631C162.521 42.4592 163.299 41.7094 163.83 40.7969C164.371 39.7964 164.656 38.6809 164.659 37.5473C164.663 36.4138 164.385 35.2965 163.85 34.2928C163.336 33.3879 162.574 32.6439 161.65 32.1465ZM162.013 39.9776C161.656 40.6129 161.123 41.1351 160.475 41.4831C159.85 41.8155 159.15 41.9881 158.44 41.9849C157.744 41.9909 157.058 41.8221 156.447 41.4944C155.828 41.1466 155.328 40.6269 155.007 40.0002C154.624 39.2364 154.438 38.3909 154.466 37.5392C154.44 36.6927 154.63 35.8533 155.019 35.098C155.348 34.4682 155.861 33.9491 156.49 33.6066C157.112 33.286 157.804 33.1185 158.506 33.1185C159.208 33.1185 159.9 33.286 160.521 33.6066C161.159 33.9496 161.681 34.4678 162.025 35.098C162.428 35.8489 162.626 36.6901 162.6 37.5392C162.627 38.3889 162.425 39.2304 162.013 39.9776V39.9776Z" fill="#31363C" />
      <path d="M169.687 33.5189V31.5909H167.671V43.5303H169.687V37.0375C169.687 34.6275 170.815 33.4215 173.073 33.4197H173.603V31.3726C172.765 31.3471 171.936 31.5429 171.201 31.9396C170.551 32.3094 170.024 32.8584 169.687 33.5189V33.5189Z" fill="#31363C" />
      <path d="M187.355 37.1028C187.372 36.0812 187.124 35.0721 186.635 34.1711C186.163 33.3152 185.454 32.6091 184.59 32.1353C183.655 31.6294 182.602 31.3743 181.535 31.3953C180.443 31.3734 179.365 31.6324 178.405 32.1467C177.503 32.6431 176.768 33.3894 176.292 34.293C175.773 35.2974 175.515 36.4128 175.54 37.5394C175.513 38.6718 175.778 39.7925 176.309 40.7972C176.796 41.7046 177.537 42.4562 178.443 42.9633C179.387 43.4824 180.454 43.7456 181.535 43.726C182.833 43.7701 184.109 43.3914 185.166 42.6486C186.118 41.9592 186.804 40.9728 187.113 39.8502H184.947C184.725 40.5007 184.294 41.0621 183.718 41.4493C183.072 41.8675 182.311 42.0776 181.538 42.0504C180.539 42.0734 179.57 41.7182 178.829 41.058C178.084 40.3965 177.674 39.4778 177.599 38.3021H187.291C187.334 37.9037 187.355 37.5034 187.355 37.1028V37.1028ZM177.619 36.6888C177.665 35.6806 178.104 34.7286 178.846 34.0322C179.554 33.3984 180.481 33.0555 181.437 33.0738C182.108 33.0636 182.772 33.2093 183.375 33.4991C183.948 33.7721 184.428 34.2024 184.757 34.7381C185.109 35.3286 185.286 36.0044 185.267 36.6888H177.619Z" fill="#31363C" />
      <g clipPath="url(#clip1_417_1582)">
        <path d="M33.7038 66.4224H187.68C192.107 66.4628 196.498 65.6391 200.6 63.9989C204.702 62.3587 208.433 59.9345 211.578 56.8663C214.723 53.7981 217.22 50.1467 218.923 46.123C220.627 42.0994 221.504 37.7832 221.504 33.4238C221.504 29.0645 220.627 24.7483 218.923 20.7247C217.22 16.701 214.723 13.0496 211.578 9.98142C208.433 6.91321 204.702 4.48896 200.6 2.84874C196.498 1.20853 192.107 0.384839 187.68 0.425246H33.7038C24.8697 0.505879 16.4253 4.01803 10.2074 10.1977C3.98946 16.3774 0.501099 24.7247 0.501099 33.4238C0.501099 42.123 3.98946 50.4703 10.2074 56.65C16.4253 62.8296 24.8697 66.3418 33.7038 66.4224V66.4224Z" fill="#F4F4F3" />
        <path d="M34.6138 17.0828C34.0926 17.4939 33.7787 18.2169 33.7787 19.1922V47.6558C33.7787 48.6283 34.0926 49.3569 34.6138 49.7624L51.1732 33.4226L34.6138 17.0828Z" fill="url(#paint0_linear_417_1582)" />
        <path d="M56.7477 27.9391L37.4557 17.1848C36.304 16.5497 35.2904 16.5639 34.6166 17.0969L51.1761 33.4396L56.7477 27.9391Z" fill="url(#paint1_linear_417_1582)" />
        <path d="M34.6137 49.7707C35.2875 50.3038 36.3126 50.3179 37.4528 49.6828L56.7448 38.9286L51.1732 33.4309L34.6137 49.7707Z" fill="url(#paint2_linear_417_1582)" />
        <path d="M64.4329 33.1446C64.3582 32.8243 64.219 32.5221 64.0237 32.2557C63.8283 31.9893 63.5807 31.7642 63.2955 31.5937C63.1861 31.5199 63.068 31.4462 62.9413 31.3753L56.7477 27.939L51.1761 33.4394L56.7477 38.9371L62.9413 35.4865C64.0787 34.8372 64.574 33.9781 64.4329 33.1446Z" fill="url(#paint3_linear_417_1582)" />
        <path d="M76.3651 25.6538C77.0069 26.0107 77.7329 26.1946 78.4699 26.1869C79.1637 26.1945 79.8478 26.0266 80.4567 25.6992C81.0423 25.3843 81.5375 24.9289 81.8964 24.3751C82.2597 23.8215 82.4802 23.1888 82.5385 22.5322V21.7014H78.0668V22.5691H81.3724C81.3233 23.3036 80.9966 23.9934 80.4567 24.5027C79.9102 24.9875 79.1935 25.2438 78.4584 25.2172C77.9109 25.2257 77.371 25.0896 76.8949 24.8231C76.4287 24.562 76.0488 24.1741 75.8007 23.706C75.5374 23.1804 75.4004 22.6022 75.4004 22.0161C75.4004 21.4301 75.5374 20.8519 75.8007 20.3263C76.0472 19.8526 76.4271 19.4589 76.8949 19.1922C77.3718 18.928 77.9113 18.793 78.4584 18.8009C78.9932 18.7895 79.5207 18.925 79.9816 19.1922C80.4174 19.4565 80.7648 19.841 80.9808 20.298H82.2765C81.9951 19.5516 81.4759 18.915 80.7965 18.4834C80.0975 18.0491 79.2848 17.8254 78.4584 17.8398C77.7214 17.832 76.9954 18.0159 76.3536 18.3728C75.722 18.723 75.2009 19.2381 74.8477 19.8613C74.4848 20.5203 74.2947 21.258 74.2947 22.0076C74.2947 22.7572 74.4848 23.495 74.8477 24.154C75.2041 24.7816 75.7291 25.3004 76.3651 25.6538Z" fill="#31363C" />
        <path d="M83.9552 26.116H88.4183V25.237H85.0379V22.4272H88.0612V21.5483H85.0379V18.8179H88.4183V17.939H83.9552V26.116Z" fill="#31363C" />
        <path d="M91.8188 26.1158H92.9015V18.8178H95.1619V17.9502H89.547V18.8178H91.8188V26.1158Z" fill="#31363C" />
        <path d="M100.754 17.9502H99.671V26.1158H100.754V17.9502Z" fill="#31363C" />
        <path d="M104.359 26.1158H105.441V18.8178H107.702V17.9502H102.087V18.8178H104.359V26.1158Z" fill="#31363C" />
        <path d="M118.088 25.6707C118.715 25.3207 119.233 24.8078 119.585 24.1878C119.946 23.5247 120.135 22.784 120.135 22.0316C120.135 21.2792 119.946 20.5385 119.585 19.8754C119.233 19.2562 118.715 18.7442 118.088 18.3953C117.44 18.0456 116.714 17.8623 115.976 17.8623C115.237 17.8623 114.511 18.0456 113.864 18.3953C113.233 18.7471 112.713 19.2618 112.358 19.8838C111.995 20.5438 111.805 21.2825 111.805 22.033C111.805 22.7835 111.995 23.5222 112.358 24.1822C112.713 24.8035 113.234 25.3181 113.864 25.6707C114.511 26.0204 115.237 26.2037 115.976 26.2037C116.714 26.2037 117.44 26.0204 118.088 25.6707V25.6707ZM113.311 23.7427C113.049 23.208 112.913 22.6222 112.913 22.0288C112.913 21.4353 113.049 20.8495 113.311 20.3148C113.557 19.8429 113.935 19.4496 114.399 19.1807C114.88 18.9186 115.42 18.781 115.97 18.781C116.519 18.781 117.06 18.9186 117.541 19.1807C118.005 19.4503 118.382 19.8434 118.629 20.3148C118.891 20.8495 119.026 21.4353 119.026 22.0288C119.026 22.6222 118.891 23.208 118.629 23.7427C118.381 24.2154 118.002 24.6089 117.535 24.8768C117.056 25.1372 116.517 25.2737 115.97 25.2737C115.423 25.2737 114.884 25.1372 114.405 24.8768C113.937 24.6073 113.558 24.2119 113.311 23.737V23.7427Z" fill="#31363C" />
        <path d="M128.082 17.939H126.999V24.429L122.643 17.939H121.563V26.116H122.643V19.6146L126.999 26.116H128.082V17.939Z" fill="#31363C" />
        <path d="M80.4106 38.1263H85.7721C85.6916 39.3175 85.1616 40.436 84.2863 41.2621C83.3991 42.0458 82.238 42.4604 81.047 42.4189C80.1593 42.4314 79.284 42.2123 78.5102 41.7838C77.755 41.3573 77.1388 40.7279 76.7336 39.9692C76.289 39.1249 76.0672 38.184 76.0886 37.2332C76.0688 36.2797 76.2905 35.3365 76.7336 34.4886C77.137 33.7264 77.7534 33.0938 78.5102 32.6655C79.284 32.237 80.1593 32.0178 81.047 32.0304C81.9135 32.0122 82.7679 32.2322 83.5146 32.6655C84.2236 33.0924 84.7878 33.7171 85.1357 34.4602H87.2377C86.7817 33.2492 85.939 32.2165 84.8363 31.5172C83.7582 30.8672 82.5253 30.5083 81.2617 30.4764C79.998 30.4446 78.7482 30.741 77.6377 31.3357C76.6149 31.9048 75.7703 32.7394 75.196 33.7486C74.6083 34.8173 74.3005 36.0135 74.3005 37.2289C74.3005 38.4443 74.6083 39.6405 75.196 40.7092C75.7731 41.7141 76.6172 42.5453 77.6377 43.1136C78.6802 43.6881 79.8582 43.9815 81.0527 43.9641C82.1767 43.9777 83.2853 43.7063 84.2719 43.1759C85.2201 42.6637 86.0229 41.9258 86.6071 41.0296C87.1954 40.1293 87.553 39.1019 87.6494 38.0355V36.7285H80.4106V38.1263Z" fill="#31363C" />
        <path d="M97.3646 33.9386C96.5247 33.5082 95.5918 33.2834 94.645 33.2834C93.6982 33.2834 92.7653 33.5082 91.9254 33.9386C91.1179 34.3741 90.452 35.0258 90.0048 35.8184C89.5217 36.6862 89.2793 37.6642 89.3023 38.6536C89.2805 39.6409 89.5165 40.6172 89.9876 41.4889C90.4199 42.2847 91.0756 42.9412 91.8765 43.3801C92.7 43.8283 93.6278 44.058 94.5687 44.0464C95.5241 44.0568 96.4665 43.8275 97.307 43.3801C98.1266 42.9425 98.8049 42.2873 99.265 41.4889C99.7602 40.6249 100.01 39.6455 99.9878 38.6536C100.012 37.6641 99.7693 36.6859 99.2852 35.8184C98.839 35.0251 98.1729 34.3731 97.3646 33.9386V33.9386ZM97.6842 40.7659C97.3731 41.3198 96.9075 41.7743 96.3424 42.0758C95.7977 42.3655 95.1878 42.5157 94.5687 42.5125C93.9655 42.517 93.3711 42.3707 92.8411 42.0872C92.2989 41.7852 91.8592 41.3325 91.577 40.7858C91.2434 40.1192 91.0813 39.3822 91.1048 38.6395C91.0821 37.9022 91.2475 37.1711 91.5856 36.513C91.8733 35.9634 92.3203 35.5105 92.8699 35.2116C93.4129 34.9305 94.0171 34.7836 94.6306 34.7836C95.2441 34.7836 95.8484 34.9305 96.3914 35.2116C96.9468 35.5113 97.402 35.9634 97.7015 36.513C98.0536 37.1668 98.2263 37.8998 98.2025 38.6395C98.22 39.3819 98.0372 40.1157 97.6727 40.7659H97.6842Z" fill="#31363C" />
        <path d="M109.712 33.9386C108.872 33.5082 107.939 33.2834 106.992 33.2834C106.045 33.2834 105.112 33.5082 104.272 33.9386C103.464 34.3725 102.797 35.0246 102.352 35.8184C101.869 36.6862 101.626 37.6642 101.649 38.6536C101.628 39.6405 101.863 40.6164 102.332 41.4889C102.766 42.2843 103.422 42.9406 104.223 43.3801C105.046 43.8282 105.973 44.0579 106.913 44.0464C107.869 44.0572 108.813 43.8279 109.654 43.3801C110.472 42.9409 111.15 42.2861 111.612 41.4889C112.106 40.6244 112.355 39.6454 112.335 38.6536C112.358 37.6638 112.114 36.6856 111.629 35.8184C111.183 35.0258 110.518 34.374 109.712 33.9386ZM110.028 40.7659C109.719 41.3202 109.254 41.775 108.689 42.0758C108.144 42.3655 107.533 42.5157 106.913 42.5125C106.31 42.5164 105.715 42.3701 105.185 42.0872C104.643 41.7857 104.204 41.3328 103.924 40.7858C103.589 40.1197 103.427 39.3823 103.452 38.6395C103.428 37.9021 103.593 37.1706 103.933 36.513C104.219 35.9638 104.665 35.5109 105.214 35.2116C105.757 34.9305 106.361 34.7836 106.975 34.7836C107.588 34.7836 108.192 34.9305 108.735 35.2116C109.292 35.5104 109.749 35.9626 110.048 36.513C110.4 37.1668 110.573 37.8998 110.549 38.6395C110.571 39.3813 110.391 40.1154 110.028 40.7659V40.7659Z" fill="#31363C" />
        <path d="M122.945 35.3531C122.581 34.7302 122.05 34.2174 121.41 33.8703C120.677 33.4677 119.848 33.2651 119.009 33.2834C118.111 33.2727 117.228 33.502 116.452 33.9468C115.686 34.3931 115.062 35.0413 114.65 35.8181C114.197 36.6835 113.972 37.6464 113.993 38.6194C113.975 39.5957 114.201 40.5613 114.65 41.432C115.055 42.225 115.679 42.8897 116.45 43.35C117.221 43.8103 118.108 44.0476 119.009 44.0348C119.849 44.0534 120.677 43.8424 121.402 43.4252C122.042 43.0592 122.573 42.5354 122.945 41.9055V44.1C122.945 45.1737 122.639 46.0091 122.027 46.6064C121.715 46.905 121.345 47.1381 120.939 47.2916C120.533 47.4451 120.1 47.5159 119.666 47.4995C118.898 47.5264 118.14 47.3291 117.486 46.9325C116.91 46.5851 116.483 46.0411 116.288 45.4043H114.56C114.629 45.9334 114.811 46.4423 115.094 46.8972C115.377 47.3522 115.754 47.743 116.202 48.0439C117.227 48.7193 118.442 49.0586 119.674 49.0136C120.605 49.0345 121.525 48.8187 122.346 48.387C123.091 47.9869 123.703 47.3833 124.108 46.6489C124.531 45.8697 124.744 44.9972 124.728 44.1142V33.4762H122.954L122.945 35.3531ZM122.461 40.6977C122.166 41.2588 121.714 41.7258 121.16 42.0444C120.612 42.3555 119.99 42.516 119.357 42.5094C118.727 42.5171 118.106 42.3564 117.561 42.0444C117.011 41.7237 116.564 41.2569 116.271 40.6977C115.935 40.0547 115.769 39.3392 115.787 38.6166C115.77 37.9031 115.936 37.1969 116.271 36.5638C116.564 36.0101 117.012 35.55 117.561 35.2369C118.111 34.9347 118.73 34.7761 119.36 34.7761C119.99 34.7761 120.61 34.9347 121.16 35.2369C121.712 35.5545 122.163 36.0181 122.461 36.5751C122.796 37.2083 122.962 37.9144 122.945 38.6279C122.963 39.3469 122.797 40.0587 122.461 40.6977V40.6977Z" fill="#31363C" />
        <path d="M129.447 29.8074H127.691V43.8619H129.447V29.8074Z" fill="#31363C" />
        <path d="M139.669 33.93C138.854 33.4881 137.935 33.2652 137.005 33.2835C136.053 33.2651 135.112 33.4908 134.276 33.9385C133.49 34.3717 132.85 35.0223 132.436 35.8098C131.982 36.6867 131.756 37.661 131.779 38.6451C131.758 39.6309 131.99 40.6061 132.453 41.4804C132.877 42.2735 133.524 42.9299 134.316 43.3715C135.137 43.8228 136.065 44.0526 137.005 44.0378C138.138 44.0772 139.251 43.7462 140.173 43.0965C141.003 42.4956 141.602 41.6349 141.871 40.6553H139.98C139.788 41.2233 139.411 41.7135 138.909 42.0503C138.346 42.4155 137.682 42.5988 137.008 42.5748C136.563 42.5892 136.119 42.5154 135.704 42.3576C135.288 42.1998 134.909 41.9614 134.589 41.6564C134.268 41.3514 134.014 40.9862 133.84 40.5823C133.666 40.1785 133.576 39.7443 133.576 39.3057H142.015C142.053 38.9399 142.073 38.5912 142.073 38.2623C142.089 37.3729 141.872 36.4944 141.445 35.7105C141.037 34.9626 140.421 34.3449 139.669 33.93ZM133.593 37.8994C133.631 37.0193 134.014 36.1879 134.661 35.5801C135.28 35.0263 136.092 34.7279 136.928 34.7465C137.512 34.7387 138.091 34.8649 138.618 35.1151C139.117 35.3544 139.535 35.7305 139.821 36.1982C140.128 36.7131 140.282 37.3026 140.265 37.8994H133.593Z" fill="#31363C" />
        <path d="M153.884 30.624H149.545V43.862H151.299V38.336H153.89C155.458 38.336 156.626 37.9722 157.392 37.2444C157.759 36.8788 158.05 36.4454 158.247 35.969C158.444 35.4925 158.543 34.9825 158.54 34.4681C158.536 33.9537 158.43 33.4451 158.226 32.9713C158.023 32.4975 157.726 32.0679 157.354 31.7071C156.559 30.9851 155.403 30.624 153.884 30.624ZM156.044 36.2946C155.581 36.7105 154.861 36.9184 153.884 36.9184H151.293V32.0559H153.884C155.787 32.0559 156.738 32.8734 156.738 34.5084C156.758 34.8363 156.706 35.1645 156.586 35.4711C156.466 35.7777 156.282 36.0556 156.044 36.2861V36.2946Z" fill="#31363C" />
        <path d="M162.471 29.8074H160.714V43.8619H162.471V29.8074Z" fill="#31363C" />
        <path d="M173.752 35.3534C173.392 34.7315 172.865 34.2187 172.229 33.8705C171.494 33.466 170.661 33.2633 169.819 33.2836C168.922 33.2729 168.038 33.5022 167.262 33.9471C166.495 34.3926 165.871 35.0409 165.46 35.8184C165.007 36.6838 164.782 37.6467 164.803 38.6197C164.786 39.5959 165.011 40.5616 165.46 41.4323C165.865 42.2235 166.486 42.8881 167.254 43.3518C168.021 43.8089 168.902 44.0456 169.799 44.0351C170.64 44.0524 171.469 43.8457 172.2 43.4368C172.846 43.0799 173.383 42.5577 173.752 41.9256V43.8621H175.526V33.4537H173.752V35.3534ZM173.271 40.6979C172.975 41.2585 172.524 41.7253 171.97 42.0447C171.421 42.3561 170.798 42.5167 170.165 42.5097C169.535 42.5173 168.915 42.3566 168.371 42.0447C167.816 41.7249 167.364 41.2583 167.066 40.6979C166.733 40.0543 166.568 39.3391 166.586 38.6168C166.568 37.9037 166.734 37.1978 167.066 36.5641C167.362 36.0108 167.81 35.5509 168.359 35.2371C168.909 34.935 169.529 34.7763 170.159 34.7763C170.789 34.7763 171.408 34.935 171.958 35.2371C172.51 35.5561 172.96 36.0193 173.26 36.5754C173.593 37.2091 173.758 37.915 173.741 38.6282C173.762 39.3458 173.6 40.0572 173.271 40.6979V40.6979Z" fill="#31363C" />
        <path d="M185.791 33.4536L182.511 41.8886L179.194 33.4536H177.245L181.509 43.7486L179.425 48.7642H181.239L187.605 33.4536H185.791Z" fill="#31363C" />
      </g>
    </g>
    <defs>
      <linearGradient id="paint0_linear_417_1582" x1="42.4112" y1="44.4122" x2="42.4922" y2="21.9595" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00E3FF" />
        <stop offset="1" stopColor="#00C2FF" />
      </linearGradient>
      <linearGradient id="paint1_linear_417_1582" x1="34.6166" y1="25.0556" x2="56.7477" y2="25.0556" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00F076" />
        <stop offset="1" stopColor="#11D574" />
      </linearGradient>
      <linearGradient id="paint2_linear_417_1582" x1="34.6166" y1="41.7922" x2="56.7477" y2="41.7922" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF3A44" />
        <stop offset="1" stopColor="#E02453" />
      </linearGradient>
      <linearGradient id="paint3_linear_417_1582" x1="51.1761" y1="33.4253" x2="64.4559" y2="33.4253" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFBB00" />
        <stop offset="1" stopColor="#FFDD00" />
      </linearGradient>
      <clipPath id="clip0_417_1582">
        <rect width="221" height="66" fill="white" transform="translate(0.193359 0.425293)" />
      </clipPath>
      <clipPath id="clip1_417_1582">
        <rect width="221" height="66" fill="white" transform="translate(0.193359 0.425293)" />
      </clipPath>
    </defs>
  </svg>
);

const AppStore = ({ title = "Download on the App Store" }) => (
  <svg viewBox="0 0 222 67" fill="none" xmlns="http://www.w3.org/2000/svg" role="presentation" className="icon icon--app-store">
    <title>{title}</title>
    <g clipPath="url(#clip0_417_1630)">
      <path d="M33.7068 66.4253H187.683C196.571 66.4253 205.095 62.9485 211.38 56.7598C217.665 50.5711 221.196 42.1774 221.196 33.4253C221.196 24.6731 217.665 16.2795 211.38 10.0908C205.095 3.90205 196.571 0.425293 187.683 0.425293H33.7068C24.8184 0.425293 16.2942 3.90205 10.0092 10.0908C3.72422 16.2795 0.193359 24.6731 0.193359 33.4253C0.193359 42.1774 3.72422 50.5711 10.0092 56.7598C16.2942 62.9485 24.8184 66.4253 33.7068 66.4253V66.4253Z" fill="#F4F4F3" />
      <path d="M57.4301 33.9045C57.433 32.724 57.7741 31.5681 58.4144 30.57C59.0547 29.5719 59.9681 28.772 61.0495 28.2623C59.44 25.9941 56.7765 24.877 53.7646 24.877C52.2242 24.877 50.7787 25.0697 49.5175 25.7842C49.1622 25.9849 48.7596 26.0906 48.3499 26.0906C47.9403 26.0906 47.5377 25.9849 47.1823 25.7842C45.9183 25.0697 44.4728 24.877 42.9352 24.877C38.0172 24.877 34.0321 27.8313 34.0321 34.0038C34.0321 40.1762 38.0172 50.1649 42.9352 50.1649C44.0467 50.1649 45.1092 49.6971 46.0882 49.2293C46.7927 48.8931 47.5655 48.7184 48.3485 48.7184C49.1315 48.7184 49.9042 48.8931 50.6088 49.2293C51.5907 49.6971 52.6532 50.1649 53.7762 50.1649C57.3006 50.1649 60.3498 45.0331 61.7924 39.8501C60.5255 39.4323 59.4233 38.6341 58.6409 37.5677C57.8584 36.5012 57.435 35.2202 57.4301 33.9045V33.9045Z" fill="#31363C" />
      <path d="M52.561 21.551C53.7917 20.2174 54.4903 18.4901 54.5277 16.6885C52.7254 16.9738 51.0796 17.8666 49.8717 19.2143C48.6638 20.562 47.9686 22.2809 47.905 24.0773C49.7167 23.8188 51.3714 22.9211 52.561 21.551V21.551Z" fill="#31363C" />
      <path d="M82.91 25.4438C83.5162 25.1513 84.0181 24.6847 84.3497 24.1056C84.6966 23.4723 84.8693 22.7608 84.8507 22.0415C84.8686 21.3196 84.696 20.6054 84.3497 19.9689C84.018 19.3864 83.5165 18.9153 82.91 18.6164C82.2135 18.2872 81.4479 18.125 80.6755 18.143H78.2223V25.906H80.6755C81.4467 25.925 82.2117 25.7667 82.91 25.4438V25.4438ZM79.2502 18.968H80.6899C81.6996 18.968 82.4742 19.2383 83.0136 19.7789C83.553 20.3195 83.8227 21.0756 83.8227 22.0471C83.8227 23.0055 83.555 23.7493 83.0194 24.2785C82.4838 24.8116 81.7064 25.0752 80.6899 25.0752H79.2502V18.968Z" fill="#31363C" />
      <path d="M90.2467 20.0852C89.7541 19.8327 89.2069 19.7009 88.6515 19.7009C88.0962 19.7009 87.549 19.8327 87.0564 20.0852C86.5835 20.3417 86.1934 20.7238 85.9305 21.1881C85.6495 21.6955 85.5076 22.2662 85.5187 22.8439C85.5061 23.4232 85.6438 23.9961 85.919 24.5083C86.1736 24.9743 86.5583 25.3589 87.0276 25.6169C87.5108 25.878 88.0543 26.0118 88.6055 26.0053C89.1658 26.0118 89.7186 25.8781 90.2122 25.6169C90.693 25.3595 91.0917 24.9757 91.3639 24.5083C91.6534 24.0006 91.7995 23.426 91.7872 22.8439C91.7998 22.2642 91.6578 21.6913 91.3755 21.1825C91.111 20.7199 90.7199 20.3397 90.2467 20.0852V20.0852ZM90.4339 24.0915C90.2502 24.4144 89.9777 24.6797 89.6478 24.857C89.328 25.028 88.9694 25.1168 88.6055 25.115C88.2503 25.118 87.9003 25.0311 87.589 24.8627C87.2694 24.6875 87.0106 24.4218 86.8461 24.1C86.6466 23.7111 86.5477 23.2796 86.5582 22.8439C86.5468 22.4109 86.6458 21.982 86.8461 21.5964C87.0157 21.2744 87.2781 21.0091 87.6005 20.8337C87.9181 20.6681 88.272 20.5816 88.6314 20.5816C88.9907 20.5816 89.3446 20.6681 89.6622 20.8337C89.989 21.009 90.2571 21.2739 90.4339 21.5964C90.629 21.9842 90.7306 22.4112 90.7306 22.8439C90.7306 23.2767 90.629 23.7037 90.4339 24.0915Z" fill="#31363C" />
      <path d="M96.7513 21.0606L98.2428 25.9061H99.3082L101.24 19.8018H100.224L98.8216 24.9365L97.3157 19.8018H96.2647L94.7271 24.9251L93.3018 19.8018H92.2479L94.1944 25.9061H95.2569L96.7513 21.0606Z" fill="#31363C" />
      <path d="M105.122 19.6911C104.723 19.6877 104.329 19.7751 103.97 19.9462C103.631 20.1088 103.344 20.3599 103.141 20.6721V19.8016H102.11V25.906H103.141V22.5292C103.102 22.0003 103.275 21.4774 103.622 21.0718C103.789 20.9027 103.99 20.7706 104.213 20.684C104.436 20.5975 104.675 20.5586 104.914 20.57C105.148 20.5579 105.382 20.5949 105.601 20.6785C105.819 20.7621 106.017 20.8903 106.181 21.0548C106.511 21.4483 106.671 21.953 106.628 22.4611V25.9145H107.644V22.308C107.644 21.4575 107.411 20.8138 106.942 20.363C106.699 20.1376 106.413 19.962 106.1 19.8466C105.788 19.7313 105.455 19.6784 105.122 19.6911V19.6911Z" fill="#31363C" />
      <path d="M109.585 17.6638H108.557V25.906H109.585V17.6638Z" fill="#31363C" />
      <path d="M112.147 25.6169C112.632 25.8783 113.176 26.0121 113.728 26.0053C114.288 26.0118 114.84 25.8781 115.332 25.6169C115.813 25.3602 116.212 24.9763 116.484 24.5083C116.773 24.0006 116.92 23.426 116.907 22.8439C116.92 22.2642 116.778 21.6913 116.495 21.1825C116.232 20.7203 115.842 20.3401 115.37 20.0852C114.877 19.8327 114.33 19.7009 113.774 19.7009C113.219 19.7009 112.672 19.8327 112.179 20.0852C111.706 20.3417 111.316 20.7238 111.053 21.1881C110.772 21.6955 110.63 22.2662 110.642 22.8439C110.63 23.4235 110.769 23.9964 111.045 24.5083C111.298 24.9737 111.68 25.3584 112.147 25.6169V25.6169ZM111.975 21.5964C112.142 21.274 112.404 21.0084 112.726 20.8337C113.044 20.6681 113.399 20.5816 113.759 20.5816C114.118 20.5816 114.473 20.6681 114.791 20.8337C115.116 21.0146 115.381 21.2845 115.554 21.6106C115.749 21.9984 115.851 22.4253 115.851 22.8581C115.851 23.2909 115.749 23.7179 115.554 24.1057C115.37 24.4286 115.098 24.6939 114.768 24.8712C114.449 25.0422 114.091 25.131 113.728 25.1292C113.372 25.1326 113.021 25.0457 112.709 24.8769C112.39 24.7015 112.132 24.4358 111.969 24.1142C111.769 23.7252 111.67 23.2938 111.681 22.8581C111.67 22.4245 111.771 21.9951 111.975 21.6106V21.5964Z" fill="#31363C" />
      <path d="M122.813 20.916C122.6 20.5522 122.29 20.2523 121.917 20.0484C121.486 19.8099 120.998 19.6904 120.503 19.7025C119.977 19.6958 119.46 19.8311 119.006 20.0938C118.556 20.3525 118.189 20.7321 117.949 21.1882C117.684 21.6963 117.552 22.2615 117.564 22.8327C117.554 23.4045 117.686 23.9701 117.949 24.48C118.186 24.9471 118.55 25.3399 119 25.6141C119.45 25.8821 119.967 26.0207 120.492 26.0139C120.985 26.025 121.472 25.9035 121.9 25.6623C122.279 25.4534 122.595 25.1478 122.813 24.7777V25.9118H123.852V19.8017H122.813V20.916ZM122.525 24.0518C122.35 24.3809 122.084 24.655 121.759 24.8429C121.439 25.0288 121.074 25.1268 120.702 25.1264C120.332 25.1268 119.969 25.0289 119.651 24.8429C119.328 24.6542 119.065 24.3801 118.891 24.0518C118.694 23.6753 118.595 23.2563 118.603 22.8327C118.595 22.4136 118.694 21.9994 118.891 21.6277C119.065 21.3029 119.329 21.0328 119.651 20.8479C119.972 20.6703 120.334 20.5784 120.702 20.5814C121.074 20.5798 121.439 20.6778 121.759 20.865C122.084 21.0498 122.349 21.321 122.525 21.6475C122.722 22.0191 122.821 22.4334 122.813 22.8525C122.82 23.269 122.723 23.681 122.531 24.0518H122.525Z" fill="#31363C" />
      <path d="M131.183 25.906V17.6638H130.144V20.8932C129.911 20.5298 129.583 20.2357 129.194 20.0426C128.779 19.824 128.316 19.7109 127.846 19.7137C127.316 19.7068 126.795 19.8421 126.337 20.105C125.887 20.3646 125.521 20.744 125.28 21.1994C125.015 21.7076 124.883 22.2727 124.895 22.8439C124.885 23.4158 125.017 23.9814 125.28 24.4912C125.518 24.9564 125.884 25.3462 126.336 25.616C126.788 25.8858 127.309 26.0246 127.837 26.0166C128.329 26.0282 128.814 25.9066 129.24 25.665C129.618 25.4533 129.931 25.1434 130.144 24.7691V25.9032L131.183 25.906ZM129.862 24.0517C129.684 24.3819 129.416 24.6561 129.087 24.8428C128.766 25.0284 128.4 25.1263 128.027 25.1263C127.657 25.1267 127.294 25.0288 126.976 24.8428C126.654 24.6536 126.392 24.3797 126.219 24.0517C126.021 23.6756 125.921 23.2563 125.931 22.8326C125.922 22.4134 126.021 21.9988 126.219 21.6276C126.392 21.3024 126.654 21.032 126.976 20.8478C127.297 20.6702 127.659 20.5783 128.027 20.5813C128.4 20.5801 128.766 20.678 129.087 20.8649C129.41 21.0509 129.674 21.3218 129.85 21.6474C130.047 22.019 130.146 22.4333 130.138 22.8524C130.147 23.2685 130.052 23.6804 129.862 24.0517V24.0517Z" fill="#31363C" />
      <path d="M136.285 25.6169C136.769 25.8783 137.312 26.0121 137.863 26.0053C138.424 26.0114 138.976 25.8778 139.47 25.6169C139.951 25.3598 140.35 24.9759 140.622 24.5083C140.914 24.0014 141.061 23.4264 141.048 22.8439C141.061 22.2642 140.919 21.6913 140.636 21.1825C140.371 20.7212 139.982 20.3413 139.51 20.0852C139.018 19.8327 138.471 19.7009 137.915 19.7009C137.36 19.7009 136.813 19.8327 136.32 20.0852C135.847 20.3417 135.457 20.7238 135.194 21.1881C134.913 21.6955 134.771 22.2662 134.782 22.8439C134.77 23.4232 134.907 23.9961 135.183 24.5083C135.436 24.9735 135.818 25.3581 136.285 25.6169V25.6169ZM136.11 21.6106C136.279 21.2886 136.542 21.0232 136.864 20.8479C137.182 20.6823 137.536 20.5957 137.895 20.5957C138.254 20.5957 138.608 20.6823 138.926 20.8479C139.252 21.0239 139.52 21.2887 139.698 21.6106C139.901 21.9953 140 22.4249 139.986 22.8581C140.002 23.2916 139.903 23.7217 139.698 24.1057C139.514 24.4293 139.24 24.6948 138.909 24.8712C138.59 25.0425 138.232 25.1313 137.869 25.1292C137.513 25.1318 137.162 25.045 136.85 24.8769C136.531 24.7015 136.273 24.4358 136.11 24.1142C135.91 23.7252 135.811 23.2938 135.822 22.8581C135.81 22.4251 135.909 21.9962 136.11 21.6106V21.6106Z" fill="#31363C" />
      <path d="M144.734 20.5701C144.968 20.5582 145.202 20.5953 145.42 20.6789C145.638 20.7624 145.836 20.8906 146.001 21.055C146.333 21.4444 146.499 21.9453 146.464 22.4528V25.9062H147.481V22.3082C147.481 21.4576 147.247 20.814 146.781 20.3631C146.536 20.1365 146.248 19.9603 145.933 19.8449C145.618 19.7295 145.283 19.6772 144.947 19.6912C144.548 19.6878 144.154 19.7752 143.795 19.9464C143.455 20.1081 143.167 20.3593 142.963 20.6722V19.8018H141.929V25.9062H142.957V22.5293C142.92 22.0006 143.092 21.4783 143.438 21.072C143.606 20.9026 143.808 20.7703 144.031 20.6838C144.254 20.5973 144.494 20.5585 144.734 20.5701V20.5701Z" fill="#31363C" />
      <path d="M154.273 25.906V25.0554H153.516C153.277 25.08 153.039 25.014 152.848 24.8711C152.775 24.7844 152.721 24.6844 152.688 24.5768C152.655 24.4693 152.643 24.3563 152.655 24.2445V20.638H154.273V19.7874H152.655V18.2649H151.627V19.8016H150.824V20.6522H151.627V24.2502C151.627 24.8418 151.77 25.2681 152.056 25.5289C152.42 25.815 152.881 25.9548 153.346 25.9202L154.273 25.906Z" fill="#31363C" />
      <path d="M156.234 22.5292C156.199 21.9983 156.377 21.4752 156.729 21.0718C156.896 20.9027 157.098 20.7705 157.321 20.684C157.544 20.5975 157.783 20.5586 158.022 20.57C158.256 20.5579 158.49 20.5949 158.708 20.6785C158.927 20.7621 159.125 20.8903 159.289 21.0548C159.623 21.4435 159.789 21.945 159.753 22.4526V25.906H160.76V22.308C160.776 21.8099 160.665 21.3158 160.438 20.8705C160.24 20.4944 159.93 20.1861 159.551 19.9859C159.159 19.7892 158.724 19.6918 158.284 19.7024C157.867 19.6963 157.455 19.7835 157.078 19.9576C156.733 20.1166 156.442 20.3682 156.237 20.6834V17.6638H155.203V25.906H156.234V22.5292Z" fill="#31363C" />
      <path d="M165.923 24.843C165.593 25.0568 165.204 25.1638 164.809 25.1492C164.548 25.1579 164.287 25.1148 164.043 25.0225C163.799 24.9301 163.576 24.7904 163.388 24.6116C163.2 24.4327 163.051 24.2185 162.949 23.9816C162.846 23.7447 162.793 23.4899 162.793 23.2325H167.746C167.768 23.0291 167.78 22.8247 167.78 22.6201C167.79 22.0986 167.664 21.5834 167.415 21.1231C167.172 20.6858 166.809 20.325 166.367 20.0825C165.89 19.8222 165.351 19.6911 164.806 19.7026C164.248 19.6918 163.696 19.8237 163.205 20.0854C162.745 20.3402 162.369 20.7214 162.125 21.1826C161.86 21.6969 161.729 22.2676 161.742 22.8441C161.73 23.4226 161.866 23.9949 162.137 24.5084C162.387 24.9722 162.765 25.3567 163.228 25.617C163.711 25.8795 164.255 26.0134 164.806 26.0054C165.469 26.0298 166.121 25.8363 166.66 25.4554C167.144 25.1063 167.495 24.607 167.657 24.0378H166.548C166.431 24.3645 166.213 24.6465 165.923 24.843V24.843ZM163.435 21.0437C163.797 20.7185 164.273 20.5428 164.763 20.5532C165.106 20.5491 165.445 20.6239 165.753 20.7715C166.046 20.912 166.292 21.1325 166.462 21.4066C166.641 21.708 166.73 22.0527 166.721 22.4018H162.808C162.83 21.8859 163.055 21.3988 163.435 21.0437V21.0437Z" fill="#31363C" />
      <path d="M138.969 35.8722C138.171 35.5429 137.35 35.2701 136.513 35.0556C135.672 34.8232 135.02 34.6162 134.555 34.4347C134.105 34.2633 133.703 33.9904 133.38 33.638C133.054 33.2637 132.884 32.7813 132.905 32.2884C132.888 31.9484 132.95 31.6091 133.089 31.2971C133.227 30.9852 133.437 30.7091 133.703 30.4908C134.29 30.0436 135.02 29.8156 135.761 29.8472C136.542 29.8092 137.31 30.0453 137.93 30.5135C138.432 30.8852 138.766 31.4359 138.86 32.0474H141.071C141.028 31.4933 140.867 30.9542 140.598 30.4656C140.329 29.977 139.959 29.5499 139.51 29.2121C138.589 28.5033 137.406 28.1479 135.96 28.146C135.043 28.1279 134.134 28.3059 133.294 28.6677C132.56 28.9819 131.934 29.4965 131.488 30.1506C131.057 30.8046 130.833 31.5708 130.846 32.3508C130.801 33.1575 131.031 33.9561 131.5 34.619C131.906 35.1572 132.448 35.5814 133.072 35.8495C133.866 36.1719 134.683 36.4345 135.517 36.6349C136.184 36.8038 136.841 37.0073 137.486 37.2445C137.952 37.4159 138.369 37.6939 138.704 38.0554C139.04 38.4521 139.213 38.9574 139.191 39.473C139.2 39.8182 139.13 40.1609 138.986 40.4757C138.842 40.7904 138.628 41.069 138.359 41.2905C137.806 41.7649 137.05 42.0012 136.093 41.9993C135.091 41.9993 134.325 41.7583 133.789 41.2706C133.276 40.8005 132.957 40.1604 132.894 39.473H130.748C130.749 40.2573 130.996 41.0221 131.457 41.6619C131.94 42.3151 132.594 42.8274 133.349 43.1448C134.211 43.5112 135.142 43.6929 136.081 43.6778C137.04 43.7005 137.99 43.4912 138.848 43.0682C139.575 42.7087 140.187 42.1582 140.616 41.4776C141.007 40.8476 141.214 40.1236 141.215 39.3851C141.258 38.577 141.024 37.7781 140.55 37.1169C140.136 36.579 139.593 36.1511 138.969 35.8722Z" fill="#31363C" />
      <path d="M83.7968 28.4294L78.2223 43.5302H80.3444L81.5768 40.1534H88.3031L89.5412 43.5302H91.6662L86.1119 28.4294H83.7968ZM82.1527 38.5401L84.9428 30.8706L87.7301 38.5401H82.1527Z" fill="#31363C" />
      <path d="M103.953 32.1579C103.063 31.6453 102.049 31.3816 101.019 31.3952C100.055 31.3734 99.1031 31.6106 98.2658 32.0813C97.5307 32.4849 96.916 33.0715 96.4835 33.7825V31.5908H94.4679V49.1951H96.4835V41.33C96.929 42.0387 97.5503 42.6241 98.2888 43.0312C99.1183 43.5004 100.062 43.7377 101.019 43.7174C102.053 43.7324 103.071 43.4608 103.956 42.9333C104.84 42.4059 105.557 41.644 106.023 40.7346C106.537 39.7355 106.795 38.6278 106.774 37.5081C106.799 36.3924 106.541 35.2882 106.023 34.2957C105.549 33.407 104.832 32.6666 103.953 32.1579V32.1579ZM104.163 39.9011C103.825 40.547 103.306 41.0842 102.668 41.4491C102.044 41.7996 101.337 41.9846 100.618 41.986C99.8989 41.9875 99.1916 41.8055 98.5653 41.4576C97.9259 41.0908 97.4041 40.5544 97.0594 39.9096C96.6882 39.1794 96.4949 38.3742 96.4949 37.5577C96.4949 36.7412 96.6882 35.9359 97.0594 35.2058C97.4048 34.5615 97.9264 34.0253 98.5653 33.6577C99.1869 33.3027 99.8939 33.1186 100.613 33.1247C101.336 33.1169 102.049 33.2969 102.68 33.6464C103.316 34.0033 103.835 34.5328 104.174 35.1718C104.557 35.8979 104.747 36.7073 104.727 37.5251C104.743 38.351 104.549 39.1677 104.163 39.9011V39.9011Z" fill="#31363C" />
      <path d="M119.274 32.1579C118.385 31.6453 117.37 31.3816 116.34 31.3952C115.376 31.3734 114.424 31.6106 113.587 32.0813C112.851 32.4834 112.236 33.0703 111.805 33.7825V31.5908H109.789V49.1951H111.805V41.33C112.248 42.0392 112.869 42.6248 113.607 43.0312C114.438 43.5001 115.383 43.7373 116.34 43.7174C117.374 43.7329 118.391 43.4614 119.276 42.9339C120.161 42.4064 120.876 41.6442 121.341 40.7346C121.858 39.7362 122.117 38.6282 122.096 37.5081C122.121 36.392 121.862 35.2875 121.341 34.2957C120.87 33.4062 120.153 32.6653 119.274 32.1579ZM119.484 39.9011C119.146 40.547 118.628 41.0842 117.99 41.4491C117.361 41.807 116.646 41.9921 115.919 41.985C115.202 41.9902 114.495 41.8051 113.875 41.4491C113.236 41.0823 112.714 40.5459 112.369 39.9011C111.998 39.1709 111.805 38.3657 111.805 37.5492C111.805 36.7327 111.998 35.9274 112.369 35.1973C112.709 34.5626 113.221 34.0325 113.847 33.6654C114.474 33.2982 115.191 33.1082 115.919 33.1162C116.644 33.1085 117.358 33.2884 117.99 33.6379C118.626 33.9948 119.145 34.5243 119.484 35.1633C119.867 35.8893 120.057 36.6988 120.037 37.5166C120.058 38.3444 119.868 39.1641 119.484 39.9011V39.9011Z" fill="#31363C" />
      <path d="M147.008 28.5825H144.993V31.5908H143.427V33.2352H144.996V40.2724C144.996 41.4349 145.276 42.2704 145.837 42.7788C146.412 43.2892 147.239 43.5415 148.359 43.5415H150.173V41.8545H148.693C148.071 41.8545 147.636 41.7326 147.386 41.4944C147.135 41.2563 147.008 40.8423 147.008 40.2611V33.2352H150.176V31.5908H147.008V28.5825Z" fill="#31363C" />
      <path d="M161.65 32.1465C160.687 31.6529 159.616 31.3953 158.53 31.3953C157.444 31.3953 156.374 31.6529 155.411 32.1465C154.483 32.6449 153.719 33.3934 153.208 34.3041C152.656 35.2943 152.378 36.4099 152.402 37.5392C152.376 38.6731 152.646 39.7945 153.185 40.7969C153.683 41.7075 154.435 42.4591 155.353 42.9631C156.297 43.4779 157.361 43.7408 158.44 43.7258C159.537 43.7398 160.619 43.4772 161.584 42.9631C162.521 42.4592 163.299 41.7094 163.83 40.7969C164.371 39.7964 164.656 38.6809 164.659 37.5473C164.663 36.4138 164.385 35.2965 163.85 34.2928C163.336 33.3879 162.574 32.6439 161.65 32.1465ZM162.013 39.9776C161.656 40.6129 161.123 41.1351 160.475 41.4831C159.85 41.8155 159.15 41.9881 158.44 41.9849C157.744 41.9909 157.058 41.8221 156.447 41.4944C155.828 41.1466 155.328 40.6269 155.007 40.0002C154.624 39.2364 154.438 38.3909 154.466 37.5392C154.44 36.6927 154.63 35.8533 155.019 35.098C155.348 34.4682 155.861 33.9491 156.49 33.6066C157.112 33.286 157.804 33.1185 158.506 33.1185C159.208 33.1185 159.9 33.286 160.521 33.6066C161.159 33.9496 161.681 34.4678 162.025 35.098C162.428 35.8489 162.626 36.6901 162.6 37.5392C162.627 38.3889 162.425 39.2304 162.013 39.9776V39.9776Z" fill="#31363C" />
      <path d="M169.687 33.5189V31.5909H167.671V43.5303H169.687V37.0375C169.687 34.6275 170.815 33.4215 173.073 33.4197H173.603V31.3726C172.765 31.3471 171.936 31.5429 171.201 31.9396C170.551 32.3094 170.024 32.8584 169.687 33.5189V33.5189Z" fill="#31363C" />
      <path d="M187.355 37.1028C187.372 36.0812 187.124 35.0721 186.635 34.1711C186.163 33.3152 185.454 32.6091 184.59 32.1353C183.655 31.6294 182.602 31.3743 181.535 31.3953C180.443 31.3734 179.365 31.6324 178.405 32.1467C177.503 32.6431 176.768 33.3894 176.292 34.293C175.773 35.2974 175.515 36.4128 175.54 37.5394C175.513 38.6718 175.778 39.7925 176.309 40.7972C176.796 41.7046 177.537 42.4562 178.443 42.9633C179.387 43.4824 180.454 43.7456 181.535 43.726C182.833 43.7701 184.109 43.3914 185.166 42.6486C186.118 41.9592 186.804 40.9728 187.113 39.8502H184.947C184.725 40.5007 184.294 41.0621 183.718 41.4493C183.072 41.8675 182.311 42.0776 181.538 42.0504C180.539 42.0734 179.57 41.7182 178.829 41.058C178.084 40.3965 177.674 39.4778 177.599 38.3021H187.291C187.334 37.9037 187.355 37.5034 187.355 37.1028V37.1028ZM177.619 36.6888C177.665 35.6806 178.104 34.7286 178.846 34.0322C179.554 33.3984 180.481 33.0555 181.437 33.0738C182.108 33.0636 182.772 33.2093 183.375 33.4991C183.948 33.7721 184.428 34.2024 184.757 34.7381C185.109 35.3286 185.286 36.0044 185.267 36.6888H177.619Z" fill="#31363C" />
    </g>
    <defs>
      <clipPath id="clip0_417_1630">
        <rect width="221" height="66" fill="white" transform="translate(0.193359 0.425293)" />
      </clipPath>
    </defs>
  </svg>
);

const Facebook = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false" role="presentation" className="icon icon--facebook">
    <title>Facebook</title>
    <path d="M20.9384 2.5117C31.2219 2.5117 39.6282 10.9181 39.6282 21.2016C39.6282 31.4851 31.2219 39.8914 20.9384 39.8914C10.6549 39.8098 2.24851 31.4851 2.24851 21.2016C2.24851 10.9181 10.6549 2.5117 20.9384 2.5117ZM20.9384 0.879395C9.7571 0.879395 0.616211 10.0203 0.616211 21.2016C0.616211 32.3828 9.7571 41.5237 20.9384 41.5237C32.1196 41.5237 41.2605 32.3828 41.2605 21.2016C41.2605 10.0203 32.038 0.879395 20.9384 0.879395Z" fill="currentColor" />
    <path d="M18.7347 31.3217H22.489V22.0176H25.5904L26.0801 18.4266H22.489V16.1413C22.489 15.0803 22.8155 14.3458 24.2846 14.3458H26.1617V11.0812C25.8353 10.9996 24.6926 10.918 23.3868 10.918C20.6119 10.918 18.7347 12.6319 18.7347 15.6516V18.3449H15.6334V21.936H18.7347V31.3217Z" fill="currentColor" />
  </svg>
);

const Instagram = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false" role="presentation" className="icon icon--facebook">
    <title>Instagram</title>
    <path d="M21.101 2.5117C31.3845 2.5117 39.7908 10.918 39.7908 21.2016C39.7908 31.4851 31.3845 39.8914 21.101 39.8914C10.8175 39.8914 2.41111 31.4851 2.41111 21.2016C2.41111 10.918 10.8175 2.5117 21.101 2.5117ZM21.101 0.879395C9.9197 0.879395 0.778809 10.0203 0.778809 21.2016C0.778809 32.3828 9.9197 41.5237 21.101 41.5237C32.2822 41.5237 41.4231 32.3828 41.4231 21.2016C41.3415 10.0203 32.2006 0.879395 21.101 0.879395Z" fill="currentColor" />
    <path d="M26.1418 11.4893H16.2965C13.591 11.4893 11.3888 13.6789 11.3888 16.375V26.1862C11.3888 28.8823 13.591 31.0769 16.2965 31.0769H26.1418C28.8473 31.0769 31.0495 28.8823 31.0495 26.1862V16.375C31.0495 13.6789 28.8473 11.4893 26.1418 11.4893ZM29.5947 26.1862C29.5947 28.0829 28.0451 29.6271 26.1418 29.6271H16.2965C14.3932 29.6271 12.8437 28.0829 12.8437 26.1862V16.375C12.8437 14.4783 14.3932 12.9341 16.2965 12.9341H26.1418C28.0451 12.9341 29.5947 14.4783 29.5947 16.375V26.1862V26.1862Z" fill="currentColor" />
    <path d="M21.2192 16.0667C18.3344 16.0667 15.9827 18.4053 15.9827 21.2851C15.9827 24.1599 18.3294 26.5035 21.2192 26.5035C24.109 26.5035 26.4558 24.1649 26.4558 21.2851C26.4508 18.4053 24.1041 16.0667 21.2192 16.0667ZM21.2192 25.0487C19.1366 25.0487 17.4375 23.3605 17.4375 21.2801C17.4375 19.2046 19.1316 17.5115 21.2192 17.5115C23.3019 17.5115 25.0009 19.1997 25.0009 21.2801C25.0009 23.3605 23.3019 25.0487 21.2192 25.0487Z" fill="currentColor" />
    <path d="M26.7696 14.5676C26.112 14.5676 25.5739 15.1039 25.5739 15.7593C25.5739 16.4147 26.112 16.9509 26.7696 16.9509C27.4273 16.9509 27.9654 16.4147 27.9654 15.7593C27.9654 15.1039 27.4273 14.5676 26.7696 14.5676Z" fill="currentColor" />
  </svg>
);

const Youtube = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false" role="presentation" className="icon icon--facebook">
    <title>Youtube</title>
    <path d="M21.2636 2.5117C31.5471 2.5117 39.9534 10.918 39.9534 21.2016C39.9534 31.4851 31.5471 39.8914 21.2636 39.8914C10.9801 39.8914 2.57371 31.4851 2.57371 21.2016C2.57371 10.918 10.9801 2.5117 21.2636 2.5117ZM21.2636 0.879395C10.0823 0.879395 0.941406 10.0203 0.941406 21.2016C0.941406 32.3828 10.0823 41.5237 21.2636 41.5237C32.4448 41.5237 41.5857 32.3828 41.5857 21.2016C41.5041 10.0203 32.3632 0.879395 21.2636 0.879395Z" fill="currentColor" />
    <path d="M23.7936 18.9161C24.12 18.9161 24.6097 18.7529 25.0178 18.1816V18.8345H26.0788V13.0398H25.0178V17.447C24.8546 17.6102 24.6097 17.8551 24.3649 17.8551C24.12 17.8551 24.0384 17.6919 24.0384 17.447V13.0398H22.9774V17.8551C23.059 18.508 23.2223 18.9161 23.7936 18.9161Z" fill="currentColor" />
    <path d="M18.9783 17.447C18.9783 18.4264 19.5496 18.9977 20.529 18.9977C21.4267 18.9977 22.0796 18.4264 22.0796 17.447V14.5905C22.0796 13.6927 21.4267 13.0398 20.529 13.0398C19.5496 13.0398 18.9783 13.6927 18.9783 14.5905V17.447ZM20.1209 14.6721C20.1209 14.3456 20.2841 14.1008 20.529 14.1008C20.8554 14.1008 21.0186 14.3456 21.0186 14.6721V17.3654C21.0186 17.6919 20.8554 17.9367 20.529 17.9367C20.2025 17.9367 20.0393 17.6919 20.0393 17.3654V14.6721H20.1209Z" fill="currentColor" />
    <path d="M16.4482 18.8346H17.6724V15.6516L19.0599 10.9995H17.8357L17.1011 14.1825L16.2034 10.9995H14.9791L16.4482 15.6516V18.8346Z" fill="currentColor" />
    <path d="M27.5479 20.1406H14.8976C13.5101 20.1406 12.4491 21.2832 12.4491 22.5891V28.7918C12.4491 30.1793 13.5101 31.2403 14.8976 31.2403H27.5479C28.9354 31.2403 29.9963 30.1793 29.9963 28.7918V22.5891C29.9963 21.2832 28.9354 20.1406 27.5479 20.1406ZM16.6115 29.4447H15.4688V23.0788H14.2446V22.0178H17.7541V23.0788H16.5299V29.4447H16.6115ZM20.6922 29.4447H19.6312V28.8734C19.468 29.1183 19.2232 29.2815 19.0599 29.3631C18.4886 29.6896 17.7541 29.6896 17.7541 28.547V23.9765H18.8151V28.2205C18.8151 28.4654 18.8967 28.6286 19.0599 28.6286C19.2232 28.6286 19.5496 28.3837 19.6312 28.2205V24.0581H20.6922V29.4447ZM24.6097 28.3021C24.6097 28.9551 24.3649 29.5264 23.712 29.5264C23.3039 29.5264 23.0591 29.3631 22.7326 29.0367V29.4447H21.6716V22.0178H22.7326V24.3846C22.9775 24.1398 23.3039 23.8949 23.6304 23.8949C24.3649 23.8949 24.6097 24.5478 24.6097 25.2824V28.3021ZM28.364 26.833H26.4053V27.8941C26.4053 28.3021 26.4053 28.6286 26.8134 28.6286C27.2214 28.6286 27.303 28.3021 27.303 27.8941V27.486H28.364V27.8941C28.364 28.9551 27.8744 29.608 26.8134 29.608C25.834 29.608 25.3443 28.8734 25.3443 27.8941V25.4456C25.3443 24.4662 25.9972 23.8133 26.895 23.8133C27.8743 23.8133 28.364 24.4662 28.364 25.4456V26.833V26.833Z" fill="currentColor" />
    <path d="M26.8949 24.7925C26.4869 24.7925 26.4053 25.0373 26.4053 25.4454V26.0167H27.303V25.4454C27.303 25.0373 27.2214 24.7925 26.8949 24.7925Z" fill="currentColor" />
    <path d="M22.8958 24.7925C22.8142 24.7925 22.7326 24.8741 22.651 24.9557V28.3835C22.7326 28.4652 22.8142 28.5468 22.8958 28.5468C23.0591 28.6284 23.3039 28.6284 23.3855 28.4651C23.4671 28.3835 23.4672 28.3019 23.4672 28.0571V25.3638C23.4672 25.2005 23.4671 25.0373 23.3855 24.9557C23.3039 24.7109 23.0591 24.7108 22.8958 24.7925Z" fill="currentColor" />
  </svg>
);

const LinkedIn = () => (
  <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false" role="presentation" className="icon icon--facebook">
    <title>LinkedIn</title>
    <path d="M20.4262 2.5117C30.7097 2.5117 39.116 10.918 39.116 21.2016C39.116 31.4851 30.7097 39.8914 20.4262 39.8914C10.1427 39.8098 1.73631 31.4851 1.73631 21.2016C1.73631 10.918 10.1427 2.5117 20.4262 2.5117ZM20.4262 0.879395C9.2449 0.879395 0.104004 10.0203 0.104004 21.2016C0.104004 32.3828 9.2449 41.5237 20.4262 41.5237C31.6074 41.5237 40.7483 32.3828 40.7483 21.2016C40.6667 10.0203 31.5258 0.879395 20.4262 0.879395Z" fill="currentColor" />
    <path d="M14.7948 17.7742H10.5508V31.3223H14.7948V17.7742Z" fill="currentColor" />
    <path d="M25.4863 17.4473C23.4459 17.4473 22.0584 18.5899 21.4871 19.6509H21.4055V17.7737H17.4064V31.3218H21.5687V24.6294C21.5687 22.8339 21.8952 21.1199 24.0988 21.1199C26.2208 21.1199 26.3024 23.1603 26.3024 24.711V31.3218H30.4648V23.8949C30.5464 20.2222 29.7303 17.4473 25.4863 17.4473Z" fill="currentColor" />
    <path d="M12.6727 15.8967C13.9785 15.8967 15.1212 14.8357 15.1212 13.4482C15.1212 12.1424 14.0602 10.9998 12.6727 10.9998C11.2852 10.9998 10.2242 12.0608 10.2242 13.4482C10.2242 14.8357 11.3669 15.8967 12.6727 15.8967Z" fill="currentColor" />
  </svg>
);

// ----------------------------------------------------------------------------
// UI Icons
// ----------------------------------------------------------------------------
const ArrowRight = () => (
  <svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false" role="presentation" className="icon icon--arrow-right">
    <path d="M34.61 40.8984L2.24805 21.0554L34.61 1.2124" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const ArrowLeft = () => (
  <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg"  focusable="false" role="presentation" className="icon icon--arrow-left">
    <path d="M1.63281 18.4424L10 10.0752L1.63281 1.70801" stroke="#2D2B72" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const CheckMark = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false" role="presentation" className="icon icon--checkmark">
    <g clipPath="url(#clip0_896_96846)">
      <path d="M8.77441 16.4573C6.80187 16.4573 4.91011 15.6737 3.51531 14.2789C2.12051 12.8841 1.33691 10.9923 1.33691 9.01978C1.33691 7.04723 2.12051 5.15547 3.51531 3.76067C4.91011 2.36587 6.80187 1.58228 8.77441 1.58228C10.747 1.58228 12.6387 2.36587 14.0335 3.76067C15.4283 5.15547 16.2119 7.04723 16.2119 9.01978C16.2119 10.9923 15.4283 12.8841 14.0335 14.2789C12.6387 15.6737 10.747 16.4573 8.77441 16.4573ZM8.77441 17.5198C11.0288 17.5198 13.1908 16.6242 14.7848 15.0302C16.3789 13.4361 17.2744 11.2741 17.2744 9.01978C17.2744 6.76544 16.3789 4.60343 14.7848 3.00937C13.1908 1.41531 11.0288 0.519775 8.77441 0.519775C6.52007 0.519775 4.35807 1.41531 2.76401 3.00937C1.16995 4.60343 0.274414 6.76544 0.274414 9.01978C0.274414 11.2741 1.16995 13.4361 2.76401 15.0302C4.35807 16.6242 6.52007 17.5198 8.77441 17.5198Z" fill="currentColor"/>
      <path d="M11.93 5.80039C11.9225 5.80773 11.9154 5.81554 11.9088 5.82377L8.21872 10.5253L5.99491 8.30046C5.84385 8.1597 5.64405 8.08307 5.4376 8.08671C5.23116 8.09035 5.03419 8.17398 4.88818 8.31998C4.74218 8.46598 4.65855 8.66296 4.65491 8.8694C4.65127 9.07585 4.7279 9.27565 4.86866 9.42671L7.68003 12.2391C7.75577 12.3147 7.84596 12.3743 7.94522 12.4143C8.04447 12.4543 8.15077 12.4739 8.25776 12.4719C8.36475 12.4699 8.47025 12.4464 8.56796 12.4028C8.66567 12.3591 8.75359 12.2962 8.82647 12.2179L13.068 6.91602C13.2124 6.76443 13.2914 6.5621 13.2878 6.35275C13.2842 6.14341 13.1984 5.94388 13.0489 5.79731C12.8994 5.65073 12.6982 5.56888 12.4888 5.56946C12.2795 5.57004 12.0787 5.65299 11.93 5.80039Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_896_96846">
        <rect width="17" height="17" fill="white" transform="translate(0.274414 0.519775)"/>
      </clipPath>
    </defs>
  </svg>
);

// ----------------------------------------------------------------------------
// Misc Icons
// ----------------------------------------------------------------------------

export {
  // Apps & Socials
  GooglePlay,
  AppStore,
  Facebook,
  Instagram,
  Youtube,
  LinkedIn,
  // UI Icons
  ArrowRight,
  ArrowLeft,
  CheckMark,
};
