import React from "react";
import { Accordion, Button, Container } from "react-bootstrap";
import { RichText } from "prismic-reactjs";

import Anchor from "../elements/Anchor";
import AnchorPrismic from "../elements/AnchorPrismic";

const Faq = ({ slice }) => {
  if (!slice) return null;

  const { title, cta, cta_label: ctaLabel } = slice.primary;

  const faqs = slice.items.map((faq, index) => {
    return (
      <Accordion.Item
        key={faq.title}
        eventKey={index}
        className="bg-white mb-3 rounded-3"
      >
        <Accordion.Header>{faq.title}</Accordion.Header>
        <Accordion.Body className="list-chechmarks">
          <RichText
            render={faq.content.richText}
            serializeHyperlink={AnchorPrismic}
          />
        </Accordion.Body>
      </Accordion.Item>
    );
  });

  return (
    <section className="slice faq mb-6">
      <div className="bg-gradient-gray clip clip--top clip--bottom">
        <Container className="py-4 py-md-6">
          <h2 className="mb-6 text-center text-primary">{title}</h2>
          {slice.items.length > 0 && (
            <Accordion className="mw-820 mx-auto mb-7">{faqs}</Accordion>
          )}
          <div className="text-center">
            <Button
              as={Anchor}
              href={cta.url}
              target={cta.target}
              variant="secondary"
              size="lg"
            >
              <RichText render={ctaLabel.richText} />
            </Button>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Faq;
