import React from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import { RichText } from "prismic-reactjs";
import { GatsbyImage } from "gatsby-plugin-image";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

import Anchor from "../elements/Anchor";
import AnchorPrismic from "../elements/AnchorPrismic";

import "../../styles/components/elements/tilt-layers.scss";
import "../../styles/components/slices/how-to.scss";

const HowTo = ({ slice }) => {
  if (!slice) return null;

  const {
    title,
    content,
    cta,
    cta_label: ctaLabel,
    image,
    image_top: imageTop,
  } = slice.primary;

  const steps = slice.items.map((item) => {
    const hasAccordion =
      item.collapsible_1_content ||
      item.collapsible_2_content ||
      item.collapsible_3_content;

    return (
      <Row className="align-items-center mb-5 mb-md-7" key={item.step_title}>
        <Col md={{ span: 6, order: item.show_image_to_left ? null : 1 }}>
          <div className="position-relative mw-500 mx-auto">
            {item.step_image_top.gatsbyImageData ? (
              <ParallaxProvider>
                <GatsbyImage
                  image={item.step_image.gatsbyImageData}
                  alt={item.step_image.alt || ""}
                />
                <Parallax
                  speed={-10}
                  translateX={[-10, 10]}
                  translateY={[0, 0]}
                  className="position-absolute top-0 left-0 w-100 h-100"
                >
                  <GatsbyImage
                    image={item.step_image_top.gatsbyImageData}
                    alt={item.step_image_top.alt || ""}
                  />
                </Parallax>
              </ParallaxProvider>
            ) : (
              <GatsbyImage
                image={item.step_image.gatsbyImageData}
                alt={item.step_image.alt || ""}
              />
            )}
          </div>
        </Col>
        <Col md={6}>
          <div className="mw-500 mx-auto">
            <h3 className="my-4 text-primary">{item.step_title}</h3>
            <div className="fs-5">
              <RichText
                render={item.step_content.richText}
                serializeHyperlink={AnchorPrismic}
              />
            </div>
            {hasAccordion && (
              <Accordion>
                {item.collapsible_1_title && (
                  <Accordion.Item className="mb-3 rounded-3" eventKey="1">
                    <Accordion.Header as="h4">
                      <RichText render={item.collapsible_1_title.richText} />
                    </Accordion.Header>
                    <Accordion.Body>
                      {item.collapsible_1_content}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {item.collapsible_1_title && (
                  <Accordion.Item className="mb-3 rounded-3" eventKey="2">
                    <Accordion.Header as="h4">
                      <RichText render={item.collapsible_2_title.richText} />
                    </Accordion.Header>
                    <Accordion.Body>
                      {item.collapsible_2_content}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {item.collapsible_1_title && (
                  <Accordion.Item className="mb-3 rounded-3" eventKey="3">
                    <Accordion.Header as="h4">
                      <RichText render={item.collapsible_3_title.richText} />
                    </Accordion.Header>
                    <Accordion.Body>
                      {item.collapsible_3_content}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            )}
          </div>
        </Col>
      </Row>
    );
  });

  return (
    <section className="slice how-to image-effect mb-6 pb-5">
      <div className="image-effect__content bg-gradient-gray clip clip--top clip--bottom">
        <Container>
          <div className="how-to__top-cta text-center">
            <Button
              as={Anchor}
              href={cta.url}
              target={cta.target}
              variant="secondary"
              size="lg"
            >
              <RichText render={ctaLabel.richText} />
            </Button>
          </div>
          <header className="py-6 py-md-7 text-center">
            <h2 className="text-primary">{title}</h2>
            <RichText
              render={content.richText}
              serializeHyperlink={AnchorPrismic}
            />
          </header>
          {steps}
          <div className="py-5 py-md-8 text-center">
            <Button
              as={Anchor}
              href={cta.url}
              target={cta.target}
              variant="secondary"
              size="lg"
            >
              <RichText render={ctaLabel.richText} />
            </Button>
          </div>
        </Container>
      </div>
      <div className="image-effect__media">
        <div className="image-effect__media-container position-relative">
          <ParallaxProvider>
            <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
            {imageTop.gatsbyImageData && (
              <Parallax
                speed={-20}
                translateY={[-30, 10]}
                className="position-absolute top-0 left-0 w-100 h-100"
              >
                <GatsbyImage
                  image={imageTop.gatsbyImageData}
                  alt={imageTop.alt}
                />
              </Parallax>
            )}
          </ParallaxProvider>
        </div>
      </div>
    </section>
  );
};

export default HowTo;
