import React from "react";
import { Col, Container, Row, Nav, Tab } from "react-bootstrap";
import { RichText } from "prismic-reactjs";

import AnchorPrismic from "../elements/AnchorPrismic";
import Icon from "../elements/Icon";
import { ArrowLeft } from "../common/Icons";

import "../../styles/components/slices/horizontal-accordion.scss";

const HorizontalAccordion = ({ slice }) => {
  if (!slice) return null;

  const { title, content } = slice.primary;

  const tabsTitles = slice.items.map((item, index) => {
    return (
      <Nav.Item className="mb-4" key={item.title}>
        <Nav.Link
          className="container-fluid py-3 bg-light rounded-3"
          eventKey={index}
        >
          <Row className="g-0 align-items-center">
            <Col xs={1} className="nav-link__icon">
              <Icon icon={item.icon} />
            </Col>
            <Col xs={11} lg={10} className="px-3 small">
              {item.title}
            </Col>
            <Col
              sm={1}
              className="d-none d-lg-block nav-link__arrow text-center"
            >
              <ArrowLeft />
            </Col>
          </Row>
        </Nav.Link>
      </Nav.Item>
    );
  });

  const tabsContent = slice.items.map((item, index) => {
    return (
      <Tab.Pane
        className="list-chechmarks"
        eventKey={index}
        key={item.content.text}
      >
        <RichText
          render={item.content.richText}
          serializeHyperlink={AnchorPrismic}
        />
      </Tab.Pane>
    );
  });

  return (
    <section className="slice horizontal-accordion mb-6">
      <Container>
        <header className="mb-6 text-center">
          <h2 className="text-primary">{title}</h2>
          <div className="fs-4 ">
            <RichText
              render={content.richText}
              serializeHyperlink={AnchorPrismic}
            />
          </div>
        </header>
        <Tab.Container defaultActiveKey={0}>
          <Row className="align-items-center">
            <Col lg={6}>
              <Nav className="flex-column mw-lg-500 mx-auto pb-4 pb-lg-0">
                {tabsTitles}
              </Nav>
            </Col>
            <Col lg={6}>
              <Tab.Content>{tabsContent}</Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </section>
  );
};

export default HorizontalAccordion;
